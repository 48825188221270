import { NothingToReportLogo } from '@energybox/react-ui-library/dist/icons';
import {
  ViewportTypes,
  useViewportType,
} from '@energybox/react-ui-library/dist/hooks';
import styles from './NothingToReportOverlay.module.css';
import { isDefined } from '@energybox/react-ui-library/dist/utils';

const NothingToReportOverlay = ({
  title,
  subtitle,
}: {
  title?: string;
  subtitle?: string;
}) => {
  const isMobile = useViewportType() === ViewportTypes.MOBILE;
  const logoSize = isMobile ? 120 : 200;

  return (
    <div className={styles.noDataFoundSection}>
      <NothingToReportLogo size={logoSize} color="var(--accent-basePlus50)" />
      <div className={styles.noDataText}>
        {isDefined(title) ? title : 'Nothing to Report'}
      </div>
      <div className={styles.subText}>
        {isDefined(subtitle) ? subtitle : 'No matching entries found'}
      </div>
    </div>
  );
};

export default NothingToReportOverlay;

import Table, {
  Columns,
} from '@energybox/react-ui-library/dist/components/Table';
import {
  FilterTimePeriod,
  IncidentCountByEquipmentData,
  IncidentCountsByEquipment,
  NotificationsOverviewTileNames,
  SitesById,
} from '@energybox/react-ui-library/dist/types';
import { global, mapValues } from '@energybox/react-ui-library/dist/utils';
import pathOr from 'ramda/src/pathOr';

import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import ShortenedSpan from '../../../components/ShortenedSpan';
import Tile from '../../../components/Tile/Tile';
import TileContent from '../../../components/Tile/TileContent';
import TileHeader from '../../../components/Tile/TileHeader';
import { useGetIncidentCountsByEquipment } from '../../../hooks/useIncidents';
import useSiteFilter from '../../../hooks/useSiteFilter';
import { useGetAllSites } from '../../../hooks/useSites';
import styles from './EquipmentWithMostIncidentsTile.module.css';
import useSiteGroupsFilter from '../../../hooks/useSiteGroupsFilter';
import NothingToReportOverlay from '../../../components/NothingToReportOverlay';

type Props = {
  className?: string;
  timePeriod: FilterTimePeriod;
};

interface ProcessedData extends IncidentCountByEquipmentData {
  siteTitle: string | null;
}

const EquipmentWithMostIncidentsTile: React.FC<Props> = ({
  className,
  timePeriod,
}) => {
  ///*** Hooks ***///
  const sitesById = useGetAllSites();
  const { selectedSiteFilters } = useSiteFilter();
  const { siteGroupWithoutSites } = useSiteGroupsFilter();

  const { isLoading, data: incidentCountsByEquipment } =
    useGetIncidentCountsByEquipment({
      from: timePeriod.fromDate.toISOString(),
      to: timePeriod.toDate.toISOString(),
    });
  ///*** End Hooks ***///

  ///*** Local Vars ***///
  const processedData = useMemo(() => {
    return processData(
      incidentCountsByEquipment,
      sitesById,
      selectedSiteFilters
    );
  }, [incidentCountsByEquipment, sitesById, selectedSiteFilters]);

  const isThereData = processedData && processedData.length > 0;
  ///*** End Local Vars ***///

  if (!isThereData || siteGroupWithoutSites) {
    return (
      <Tile className={className} isLoading={isLoading}>
        <TileHeader
          title={NotificationsOverviewTileNames.EquipmentWithMostIncidents}
          // tooltipDescription={TOOLTIP_DESCRIPTION}
        />
        <NothingToReportOverlay subtitle="" />
      </Tile>
    );
  }

  return (
    <Tile className={className} isLoading={isLoading}>
      <TileHeader
        title={NotificationsOverviewTileNames.EquipmentWithMostIncidents}
        // tooltipDescription={TOOLTIP_DESCRIPTION}
      />

      <TileContent>
        <Table
          className={styles.table}
          columns={columns}
          data={processedData}
        />
      </TileContent>
    </Tile>
  );
};

const columns: Columns<ProcessedData>[] = [
  {
    header: '#',
    width: '5%',
    cellContent: (data: ProcessedData, index: number) => (
      <span className={styles.index}>{index + 1}</span>
    ),
  },
  {
    header: 'Equipment',
    width: '35%',
    cellContent: (data: ProcessedData) => {
      return (
        <Link to={`/sites/${data.siteId}/equipment/${data.equipmentId}`}>
          <ShortenedSpan
            content={data.equipmentTitle || global.NOT_AVAILABLE}
            maxStringLength={16}
            arrowDirection="bottom"
          />
        </Link>
      );
    },
  },
  {
    header: 'Site',
    width: '35%',
    cellContent: (data: ProcessedData) => {
      return (
        <span>
          <ShortenedSpan
            content={data.siteTitle || global.NOT_AVAILABLE}
            maxStringLength={16}
            arrowDirection="bottom"
          />
        </span>
      );
    },
  },
  {
    header: 'Incidents',
    rightAlignContent: true,
    width: '25%',
    cellContent: (data: ProcessedData) => <div>{data.totalIncidentCount}</div>,
  },
];

const processData = (
  incidentCountsByEquipment: IncidentCountsByEquipment | undefined,
  sitesById: SitesById,
  selectedSiteFilters: number[]
) => {
  if (!incidentCountsByEquipment) return [];

  const mappedData: ProcessedData[] = mapValues(
    incidentCountsByEquipment,
    (incidentCountByEquipment: IncidentCountByEquipmentData) => {
      const siteId = incidentCountByEquipment.siteId;

      return {
        ...incidentCountByEquipment,
        siteTitle: pathOr(null, [siteId, 'title'], sitesById),
      };
    }
  );

  const sortedData = mappedData
    .filter(({ siteId }) => {
      if (selectedSiteFilters.length === 0) return true;
      return selectedSiteFilters.includes(siteId);
    })
    .sort(
      (dataA, dataB) => dataB.totalIncidentCount - dataA.totalIncidentCount
    );

  return sortedData.slice(0, 5);
};

export default EquipmentWithMostIncidentsTile;

import {
  LongExtraLongSkeletonCell,
  ShortMediumSkeletonCell,
  ShortSkeletonCell,
} from '@energybox/react-ui-library/dist/components';
import {
  Columns,
  TableGroupHeader,
} from '@energybox/react-ui-library/dist/components/Table';
import {
  IncidentCountByPriority,
  IncidentCountBySiteData,
  IncidentPriority,
  IncidentStatus,
  OpacityIndex,
  SortDirection,
} from '@energybox/react-ui-library/dist/types';
import {
  genericTableSort,
  global,
  SORT_IGNORED_VALUES,
  createTimeFilterMapping,
} from '@energybox/react-ui-library/dist/utils';
import startOfDay from 'date-fns/startOfDay';
import pathOr from 'ramda/src/pathOr';
import { default as React, useState } from 'react';
import { Link } from 'react-router-dom';
import ShortenedSpan from '../../../../components/ShortenedSpan';
import SeeAllPage from '../../../../components/views/SeeAllPage';
import { useSearchFilter, useTimeFilter } from '../../../../hooks/useFilters';
import { useGetIncidentCountsBySite } from '../../../../hooks/useIncidents';
import useRefreshTimeRange from '../../../../hooks/useRefreshTimeRange';
import { useGetAllSites } from '../../../../hooks/useSites';
import * as Routes from '../../../../routes';
import {
  processData,
  ProcessedData,
} from '../../../TodayTiles/ActiveIncidentCountsTile/ActiveIncidentCountsTile';
import usePaginationFilter from '../../../../hooks/usePaginationFilter';
import NothingToReportOverlay from '../../../../components/NothingToReportOverlay';

const ActiveIncidentCountsPage: React.FC = () => {
  ///*** Hooks ***///
  const [timeRange, setTimeRange] = useState({
    from: startOfDay(new Date()).toISOString(),
    to: new Date().toISOString(),
  });

  const sitesById = useGetAllSites();
  const { setTimeFilter } = useTimeFilter();
  const { isLoading, data: countsBySite } = useGetIncidentCountsBySite({
    from: timeRange.from,
    to: timeRange.to,
    incidentStatus: IncidentStatus.ACTIVE,
  });

  //setInterval for every 15 minutes if the user keeps this window open
  useRefreshTimeRange(setTimeRange);

  ///*** Local vars + Functions ***///
  const dataToDisplay = processData({
    data: countsBySite || {},
    sitesById,
  });
  /** Search Filter */
  const {
    query,
    setQuery,
    filteredList: filterSearchList,
  } = useSearchFilter(dataToDisplay, [['siteTitle']]);
  const isThereData = dataToDisplay && dataToDisplay.length > 0;
  const numberOfSites = dataToDisplay.length;
  const totalIncidentCount = calculateTotalIncidentCount(dataToDisplay);

  const setDateFilterToToday = () => {
    const { today } = createTimeFilterMapping();
    setTimeFilter(today);
  };

  const columns: Columns<ProcessedData>[] = [
    {
      header: 'Site',
      width: '25%',
      cellContent: ({ siteTitle }) => (
        <div>
          <ShortenedSpan
            content={siteTitle}
            maxStringLength={45}
            arrowDirection={'bottom'}
          />
        </div>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <LongExtraLongSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'siteTitle',
        ]);
      },
    },
    {
      header: 'Number of Incidents',
      width: '15%',
      align: 'right',
      rightAlignContent: true,
      defaultSortDirection: SortDirection.DESC,
      cellContent: ({ siteId, incidentCountTotal }) => (
        <Link onClick={setDateFilterToToday} to={`/sites/${siteId}/incidents`}>
          {incidentCountTotal}
        </Link>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortMediumSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'incidentCountTotal',
        ]);
      },
    },
    {
      header: 'Low',
      width: '10%',
      align: 'right',
      rightAlignContent: true,
      defaultSortDirection: SortDirection.DESC,
      cellContent: ({ incidentCountByPriority }) => (
        <div>
          {determineIncidentPriorityCount(
            incidentCountByPriority,
            IncidentPriority.LOW
          )}
        </div>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => <ShortSkeletonCell />,
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'incidentCountByPriority',
          IncidentPriority.LOW,
          'incidentCount',
        ]);
      },
    },
    {
      header: 'Medium',
      width: '10%',
      align: 'right',
      rightAlignContent: true,
      defaultSortDirection: SortDirection.DESC,
      cellContent: ({ incidentCountByPriority }) => (
        <div>
          {determineIncidentPriorityCount(
            incidentCountByPriority,
            IncidentPriority.MEDIUM
          )}
        </div>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'incidentCountByPriority',
          IncidentPriority.MEDIUM,
          'incidentCount',
        ]);
      },
    },
    {
      header: 'High',
      width: '10%',
      align: 'right',
      rightAlignContent: true,
      defaultSortDirection: SortDirection.DESC,
      cellContent: ({ incidentCountByPriority }) => (
        <div>
          {determineIncidentPriorityCount(
            incidentCountByPriority,
            IncidentPriority.HIGH
          )}
        </div>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'incidentCountByPriority',
          IncidentPriority.HIGH,
          'incidentCount',
        ]);
      },
    },
    {
      header: 'Critical',
      width: '5%',
      align: 'right',
      rightAlignContent: true,
      defaultSortDirection: SortDirection.DESC,
      cellContent: ({ incidentCountByPriority }) => (
        <div>
          {determineIncidentPriorityCount(
            incidentCountByPriority,
            IncidentPriority.HIGHEST
          )}
        </div>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'incidentCountByPriority',
          IncidentPriority.HIGHEST,
          'incidentCount',
        ]);
      },
    },
    {
      header: '',
      width: '10%',
      cellContent: () => <div />,
    },
  ];

  const { currentPage, rowLimit, setPagination } = usePaginationFilter(
    dataToDisplay?.length
  );

  return (
    <SeeAllPage
      title="Sites with Active Incidents"
      headerName="Sites with Active Incidents"
      backRoute={Routes.TODAY}
      searchProps={{
        query,
        onChange: setQuery,
        error: filterSearchList.length === 0,
      }}
      alternativeContent={
        !isLoading && !isThereData ? (
          <NothingToReportOverlay subtitle="" />
        ) : undefined
      }
      disableDateFilter
      summaryStatistics={[
        {
          value: numberOfSites,
          title: 'Number of Sites',
          bold: true,
        },
        {
          value: totalIncidentCount,
          title: 'Number of Incidents',
          bold: true,
        },
      ]}
      tableProps={{
        groupHeaders,
        columns,
        data: filterSearchList,
        dataIsLoading: isLoading,
        listView: true,
        rowLimitFromPaginationHook: rowLimit,
        currentPageFromPaginationHook: currentPage,
        setPagination: setPagination,
      }}
    ></SeeAllPage>
  );
};

const groupHeaders: TableGroupHeader[] = [
  {
    header: '',
    colSpan: 2,
  },
  {
    header: 'Incident Priority Level',
    colSpan: 4,
  },
  {
    header: '',
    colSpan: 1,
  },
];

const calculateTotalIncidentCount = (data: IncidentCountBySiteData[]) => {
  return data.reduce((acc, curr) => {
    return curr.incidentCountTotal + acc;
  }, 0);
};

const determineIncidentPriorityCount = (
  data: IncidentCountByPriority,
  priority: IncidentPriority
) => {
  return pathOr(global.NOT_AVAILABLE, [priority, 'incidentCount'], data);
};

export default ActiveIncidentCountsPage;

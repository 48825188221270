import {
  FilterTimePeriod,
  Locale,
  Notification,
  NotificationsOverviewTileNames,
  SentinelTypeToLabel,
} from '@energybox/react-ui-library/dist/types';
import {
  DefaultChartStyles,
  genericYAxisTickFormatter,
  global,
} from '@energybox/react-ui-library/dist/utils';
import { differenceInDays, endOfDay, format, subDays } from 'date-fns';

import React, { useMemo } from 'react';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import Tile from '../../../components/Tile/Tile';
import TileContent from '../../../components/Tile/TileContent';
import TileHeader from '../../../components/Tile/TileHeader';
import useAppLocale from '../../../hooks/useAppLocale';
import useFilteredNotifications from '../../../hooks/useFilteredNotifications';
import useSiteFilter from '../../../hooks/useSiteFilter';
import { GenericMonthDayDateFormat } from '../../../types/shared';
import styles from './DailyNotificationsGraphTile.module.css';
import useSiteGroupsFilter from '../../../hooks/useSiteGroupsFilter';
import NothingToReportOverlay from '../../../components/NothingToReportOverlay';

type Props = {
  className?: string;
  timePeriod: FilterTimePeriod;
};

const DailyNotificationsGraphTile: React.FC<Props> = ({
  className,
  timePeriod,
}) => {
  const locale = useAppLocale();
  const { selectedSiteFilters } = useSiteFilter();
  const { siteGroupWithoutSites } = useSiteGroupsFilter();
  // TODO: this doesn't seem to re-fetch when siteIds change
  const { data: notifications, isLoading } = useFilteredNotifications(
    {
      fromDate: timePeriod.fromDate,
      toDate: timePeriod.toDate,
      siteIds: selectedSiteFilters,
    },
    true
  );

  const processedNotifications = useMemo(() => {
    return processNotifications(notifications, timePeriod, locale);
  }, [notifications, timePeriod, locale]);

  const isThereData =
    processedNotifications && processedNotifications.length > 0;

  if (!isThereData || siteGroupWithoutSites) {
    return (
      <Tile className={className} isLoading={isLoading}>
        <TileHeader
          title={NotificationsOverviewTileNames.DailyNotifications}
          // tooltipDescription={TOOLTIP_DESCRIPTION}
        />
        <NothingToReportOverlay subtitle="" />
      </Tile>
    );
  }

  return (
    <Tile className={className} isLoading={isLoading}>
      <TileHeader
        title={NotificationsOverviewTileNames.DailyNotifications}
        // tooltipDescription={TOOLTIP_DESCRIPTION}
      />

      <TileContent>
        <ResponsiveContainer>
          <ComposedChart data={processedNotifications || []}>
            <CartesianGrid
              vertical={false}
              stroke={DefaultChartStyles.axisLineColor}
            />
            <YAxis
              width={25}
              type="number"
              tick={DefaultChartStyles.tick}
              tickFormatter={genericYAxisTickFormatter}
              axisLine={false}
              tickLine={false}
            />
            <XAxis
              dataKey="timestamp"
              type="category"
              tick={DefaultChartStyles.tick}
              tickFormatter={xAxisTickFormatter}
              axisLine={{
                stroke: DefaultChartStyles.axisLineColor,
              }}
              tickLine={false}
            />
            <Bar dataKey="count" fill="#3fb8c3" />
            <Tooltip content={CustomTooltip} />
          </ComposedChart>
        </ResponsiveContainer>
      </TileContent>
    </Tile>
  );
};

const xAxisTickFormatter = (tickItem: string) => {
  return format(+tickItem, GenericMonthDayDateFormat);
};

const createInitialAccumulator = (timePeriod: FilterTimePeriod) => {
  const timeRangeDayDifference = differenceInDays(
    timePeriod.toDate,
    timePeriod.fromDate
  );
  let newDate = endOfDay(timePeriod.toDate);

  const initializedAccumulator = {
    [newDate.valueOf()]: {
      count: 0,
      countBySentinelType: {},
    },
  };

  if (timeRangeDayDifference !== 0) {
    for (let i = 1; i <= timeRangeDayDifference; i++) {
      newDate = subDays(newDate, 1);

      initializedAccumulator[newDate.valueOf()] = {
        count: 0,
        countBySentinelType: {},
      };
    }
  }

  return initializedAccumulator;
};

const processNotifications = (
  notifications: Notification[],
  timePeriod: FilterTimePeriod,
  locale: Locale
) => {
  const initialAccumulator = createInitialAccumulator(timePeriod);

  const reducer = (acc, notification: Notification) => {
    let updatedReducer = { ...acc };

    notification.handlingLogs.forEach((log) => {
      const key = endOfDay(new Date(log.at)).valueOf();

      //do not include notifications that occur outside of day range
      if (!updatedReducer[key]) return;

      const updatedCount = updatedReducer[key]['count'] + 1;
      const countByType =
        updatedReducer[key]['countBySentinelType'][notification.sentinelType];

      const updatedCountBySentinelType = {
        ...updatedReducer[key]['countBySentinelType'],
        [notification.sentinelType]: !countByType ? 1 : countByType + 1,
      };

      updatedReducer = {
        ...updatedReducer,
        [key]: {
          count: updatedCount,
          countBySentinelType: updatedCountBySentinelType,
        },
      };
    });
    return updatedReducer;
  };

  const reducedNotifications = notifications.reduce(
    reducer,
    initialAccumulator
  );

  const sortedData = Object.keys(reducedNotifications)
    .map((key) => ({
      timestamp: +key,
      dateString: format(+key, locale.dateFormat),
      count: reducedNotifications[key]['count'],
      countBySentinelType: reducedNotifications[key]['countBySentinelType'],
    }))
    .sort((a, b) => a.timestamp - b.timestamp);

  return sortedData;
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload[0]) {
    const innerPayload = payload[0].payload;
    const isThereData =
      innerPayload.countBySentinelType &&
      Object.keys(innerPayload.countBySentinelType).length > 0;

    return (
      <div className={styles.tooltipContainer}>
        <div className={styles.gridContainer}>
          {isThereData ? (
            Object.keys(innerPayload.countBySentinelType).map(
              (sentinelType) => {
                return (
                  <React.Fragment key={sentinelType}>
                    <span className={styles.tooltipNumber}>
                      {innerPayload.countBySentinelType[sentinelType]}
                    </span>
                    <span className={styles.tooltipSentinelType}>
                      {SentinelTypeToLabel[sentinelType]}
                    </span>
                  </React.Fragment>
                );
              }
            )
          ) : (
            <span>{global.NOT_AVAILABLE}</span>
          )}
        </div>
        <div className={styles.tooltipDate}>{innerPayload.dateString}</div>
      </div>
    );
  }

  return null;
};

export default DailyNotificationsGraphTile;

export enum ReportType {
  CSV = 'CSV_REPORT',
  EXCEL = 'EXCEL_REPORT',
}

export enum ReportTemplate {
  EQUIPMENT_PERFORMANCE = 'equipmentPerformance',
  DAILY_ENERGY_CONSUMPTION = 'dailyEnergyConsumption',
  MULTI_SITE_TEMP_COMPLIANCE = 'multiSiteTemperatureCompliance',
  TEMP_COMPLIANCE = 'temperatureCompliance',
  MULTI_SITE_ENERGY_CONSUMPTION = 'multiSiteEnergyConsumption',
  ENERGY_CONSUMPTION = 'rawEnergyData',
  RAW_TEMPERATURE_DATA = 'rawTemperatureData',
  HVAC_CONTROLLED_PERFORMANCE = 'hvacControlledPerformance',
}

export enum PDFReportTemplate {
  EQUIPMENT_PERFORMANCE_SUMMARY = 'equipmentPerformanceSummary',
  MONTHLY_TEMP_CHECK = 'monthlyTempCheck',
  ENERGY_USAGE = 'energyUsage',
  STORAGE_USAGE = 'storageUsage',
  TEMPERATURE_RECORD = 'temperatureRecord',
}

export enum ReportStatus {
  TRIGGER_LOADING,
  TRIGGER_SUCCESS,
  TRIGGER_FAILED,
}

export interface StatusDetail {
  status: ReportStatus;
  msg: string;
}

export interface ToReportRequestBody {
  reportTemplate?: ReportTemplate;
  reportFromDate?: string | number;
  reportToDate?: string | number;
  searchFilter?: ToReportSearchFilter;
  equipmentGroupId?: number;
  siteId?: number | string;
  organizationId?: number;
  measurementSystem?: string;
  aggregationLevel?: string;
}

export interface ToReportSearchFilter {
  siteIds?: number[];
  equipmentTypeId?: number[];
  vendor?: string[];
}

export enum TimeAggregation {
  FIFTEEN_MINS = '15m',
  ONE_HOUR = '1h',
  ONE_DAY = '1d',
}

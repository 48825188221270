export enum Actions {
  EQUIPMENT_TYPES_SUCCESS = '@@equipmentTags/TYPES_SUCCESS',
  EQUIPMENT_TYPES_LOADING = '@@equipmentTags/TYPES_LOADING',
  EQUIPMENT_TYPES_ERROR = '@@equipmentTags/TYPES_ERROR',

  EQUIPMENT_GROUPS_SUCCESS = '@@equipmentTags/GROUPS_SUCCESS',
  EQUIPMENT_GROUPS_LOADING = '@@equipmentTags/GROUPS_LOADING',
  EQUIPMENT_GROUPS_ERROR = '@@equipmentTags/GROUPS_ERROR',
}

export const getEquipmentTypes = () => ({
  type: 'API_GET',
  path: '/api/v1/equipment-types',
  loading: Actions.EQUIPMENT_TYPES_LOADING,
  success: Actions.EQUIPMENT_TYPES_SUCCESS,
  error: Actions.EQUIPMENT_TYPES_ERROR,
});

export const getEquipmentGroups = () => ({
  type: 'API_GET',
  path: '/api/v1/equipment-groups',
  loading: Actions.EQUIPMENT_GROUPS_LOADING,
  success: Actions.EQUIPMENT_GROUPS_SUCCESS,
  error: Actions.EQUIPMENT_GROUPS_ERROR,
});

import React from 'react';
import NotificationIconFactory from '../../icons/IconNotificationFactory';
import styles from './AlertChip.module.css';

type Props = {
  status: 'active' | 'inactive';
};

const AlertChip = ({ status }: Props) => (
  <span className={status === 'active' ? styles.active : styles.inactive}>
    <NotificationIconFactory size={20} id={status} />
  </span>
);

export default AlertChip;

import {
  OrgControlsSavingsAnalyticsApiFilters,
  OrgTemperatureComplianceApiFilters,
  SitePeakDemandReportType,
  SiteTemperatureComplianceApiFilters,
} from '@energybox/react-ui-library/dist/types';
import { Service } from '../config';
import { formatAPIFilters } from '../util';
import {
  formatDateForNotificationsAPI,
  formatToIsoDate,
} from '@energybox/react-ui-library/dist/utils';
import { startOfMonth } from 'date-fns';

export enum Actions {
  GET_COOLER_RECOMMENDATIONS_SUCCESS = '@@analytics/GET_COOLER_RECOMMENDATIONS_SUCCESS',
  GET_COOLER_RECOMMENDATIONS_LOADING = '@@analytics/GET_COOLER_RECOMMENDATIONS_LOADING',
  GET_COOLER_RECOMMENDATIONS_ERROR = '@@analytics/GET_COOLER_RECOMMENDATIONS_ERROR',

  GET_ENERGY_RECOMMENDATIONS_SUCCESS = '@@analytics/GET_ENERGY_RECOMMENDATIONS_SUCCESS',
  GET_ENERGY_RECOMMENDATIONS_LOADING = '@@analytics/GET_ENERGY_RECOMMENDATIONS_LOADING',
  GET_ENERGY_RECOMMENDATIONS_ERROR = '@@analytics/GET_ENERGY_RECOMMENDATIONS_ERROR',

  GET_HUMIDITY_RECOMMENDATIONS_SUCCESS = '@@analytics/GET_HUMIDITY_RECOMMENDATIONS_SUCCESS',
  GET_HUMIDITY_RECOMMENDATIONS_LOADING = '@@analytics/GET_HUMIDITY_RECOMMENDATIONS_LOADING',
  GET_HUMIDITY_RECOMMENDATIONS_ERROR = '@@analytics/GET_HUMIDITY_RECOMMENDATIONS_ERROR',

  GET_ENERGY_REPORT_BY_SITE_ID_SUCCESS = '@@analytics/GET_ENERGY_REPORT_BY_SITE_ID_SUCCESS',
  GET_ENERGY_REPORT_BY_SITE_ID_LOADING = '@@analytics/GET_ENERGY_REPORT_BY_SITE_ID_LOADING',
  GET_ENERGY_REPORT_BY_SITE_ID_ERROR = '@@analytics/GET_ENERGY_REPORT_BY_SITE_ID_ERROR',

  GET_SITE_PEAK_DEMAND_REPORT_SUCCESS = '@@analytics/GET_SITE_PEAK_DEMAND_REPORT_SUCCESS',
  GET_SITE_PEAK_DEMAND_REPORT_LOADING = '@@analytics/GET_SITE_PEAK_DEMAND_REPORT_LOADING',
  GET_SITE_PEAK_DEMAND_REPORT_ERROR = '@@analytics/GET_SITE_PEAK_DEMAND_REPORT_ERROR',

  GET_SCHEDULER_SAVINGS_BY_SITE_ID_SUCCESS = '@@analytics/GET_SCHEDULER_SAVINGS_BY_SITE_ID_SUCCESS',
  GET_SCHEDULER_SAVINGS_BY_SITE_ID_LOADING = '@@analytics/GET_SCHEDULER_SAVINGS_BY_SITE_ID_LOADING',
  GET_SCHEDULER_SAVINGS_BY_SITE_ID_ERROR = '@@analytics/GET_SCHEDULER_SAVINGS_BY_SITE_ID_ERROR',

  GET_CONTROLS_SAVINGS_BY_SITE_ID_SUCCESS = '@@analytics/GET_CONTROLS_SAVINGS_BY_SITE_ID_SUCCESS',
  GET_CONTROLS_SAVINGS_BY_SITE_ID_LOADING = '@@analytics/GET_CONTROLS_SAVINGS_BY_SITE_ID_LOADING',
  GET_CONTROLS_SAVINGS_BY_SITE_ID_ERROR = '@@analytics/GET_CONTROLS_SAVINGS_BY_SITE_ID_ERROR',

  GET_TEMPERATURE_COMPLIANCE_BY_ORG_ID_SUCCESS = '@@analytics/GET_TEMPERATURE_COMPLIANCE_BY_ORG_ID_SUCCESS',
  GET_TEMPERATURE_COMPLIANCE_BY_ORG_ID_LOADING = '@@analytics/GET_TEMPERATURE_COMPLIANCE_BY_ORG_ID_LOADING',
  GET_TEMPERATURE_COMPLIANCE_BY_ORG_ID_ERROR = '@@analytics/GET_TEMPERATURE_COMPLIANCE_BY_ORG_ID_ERROR',

  GET_TEMPERATURE_COMPLIANCE_BY_SITE_ID_SUCCESS = '@@analytics/GET_TEMPERATURE_COMPLIANCE_BY_SITE_ID_SUCCESS',
  GET_TEMPERATURE_COMPLIANCE_BY_SITE_ID_LOADING = '@@analytics/GET_TEMPERATURE_COMPLIANCE_BY_SITE_ID_LOADING',
  GET_TEMPERATURE_COMPLIANCE_BY_SITE_ID_ERROR = '@@analytics/GET_TEMPERATURE_COMPLIANCE_BY_SITE_ID_ERROR',

  GET_ORGANIZATION_ENERGY_CONSUMPTION_SUCCESS = '@@analytics/GET_ORGANIZATION_ENERGY_CONSUMPTION_SUCCESS',
  GET_ORGANIZATION_ENERGY_CONSUMPTION_LOADING = '@@analytics/GET_ORGANIZATION_ENERGY_CONSUMPTION_LOADING',
  GET_ORGANIZATION_ENERGY_CONSUMPTION_ERROR = '@@analytics/GET_ORGANIZATION_ENERGY_CONSUMPTION_ERROR',

  GET_ORG_EQUIPMENT_TYPE_ENERGY_SUCCESS = '@@analytics/GET_ORG_EQUIPMENT_TYPE_ENERGY_SUCCESS',
  GET_ORG_EQUIPMENT_TYPE_ENERGY_LOADING = '@@analytics/GET_ORG_EQUIPMENT_TYPE_ENERGY_LOADING',
  GET_ORG_EQUIPMENT_TYPE_ENERGY_ERROR = '@@analytics/GET_ORG_EQUIPMENT_TYPE_ENERGY_ERROR',

  GET_SITE_OVERRIDES_REPORT_SUCCESS = '@@analytics/GET_SITE_OVERRIDES_REPORT_SUCCESS',
  GET_SITE_OVERRIDES_REPORT_LOADING = '@@analytics/GET_SITE_OVERRIDES_REPORT_LOADING',
  GET_SITE_OVERRIDES_REPORT_ERROR = '@@analytics/GET_SITE_OVERRIDES_REPORT_ERROR',

  GET_CONTROLS_SAVINGS_SUCCESS = '@@analytics/GET_SITE_CONTROLS_SAVINGS_SUCCESS',
  GET_CONTROLS_SAVINGS_LOADING = '@@analytics/GET_SITE_CONTROLS_SAVINGS_LOADING',
  GET_CONTROLS_SAVINGS_ERROR = '@@analytics/GET_SITE_CONTROLS_SAVINGS_ERROR',

  GET_HVAC_OUTLIERS_BY_SITE_ID_SUCCESS = '@@analytics/GET_HVAC_OUTLIERS_BY_SITE_ID_SUCCESS',
  GET_HVAC_OUTLIERS_BY_SITE_ID_LOADING = '@@analytics/GET_HVAC_OUTLIERS_BY_SITE_ID_LOADING',
  GET_HVAC_OUTLIERS_BY_SITE_ID_ERROR = '@@analytics/GET_HVAC_OUTLIERS_BY_SITE_ID_ERROR',

  GET_EQUIPMENT_ENERGY_COMPARE_SUCCESS = '@@analytics/GET_EQUIPMENT_ENERGY_COMPARE_SUCCESS',
  GET_EQUIPMENT_ENERGY_COMPARE_LOADING = '@@analytics/GET_EQUIPMENT_ENERGY_COMPARE_LOADING',
  GET_EQUIPMENT_ENERGY_COMPARE_ERROR = '@@analytics/GET_EQUIPMENT_ENERGY_COMPARE_ERROR',

  GET_SITE_OPERATING_HOURS_ENERGY_SUCCESS = '@@analytics/GET_SITE_OPERATING_HOURS_ENERGY_SUCCESS',
  GET_SITE_OPERATING_HOURS_ENERGY_LOADING = '@@analytics/GET_SITE_OPERATING_HOURS_ENERGY_LOADING',
  GET_SITE_OPERATING_HOURS_ENERGY_ERROR = '@@analytics/GET_SITE_OPERATING_HOURS_ENERGY_ERROR',

  GET_TEMPERATURE_RECOMMENDATIONS_BY_SITE_SUCCESS = '@@analytics/GET_TEMPERATURE_RECOMMENDATIONS_BY_SITE_SUCCESS',
  GET_TEMPERATURE_RECOMMENDATIONS_BY_SITE_LOADING = '@@analytics/GET_TEMPERATURE_RECOMMENDATIONS_BY_SITE_LOADING',
  GET_TEMPERATURE_RECOMMENDATIONS_BY_SITE_ERROR = '@@analytics/GET_TEMPERATURE_RECOMMENDATIONS_BY_SITE_ERROR',

  START_ORGANIZATION_CO2_REPORT_SUCCESS = '@@analytics/START_ORGANIZATION_CO2_REPORT_SUCCESS',
  START_ORGANIZATION_CO2_REPORT_LOADING = '@@analytics/START_ORGANIZATION_CO2_REPORT_LOADING',
  START_ORGANIZATION_CO2_REPORT_ERROR = '@@analytics/START_ORGANIZATION_CO2_REPORT_ERROR',

  GET_ORGANIZATION_CO2_REPORT_SUCCESS = '@@analytics/GET_ORGANIZATION_CO2_REPORT_SUCCESS',
  GET_ORGANIZATION_CO2_REPORT_LOADING = '@@analytics/GET_ORGANIZATION_CO2_REPORT_LOADING',
  GET_ORGANIZATION_CO2_REPORT_ERROR = '@@analytics/GET_ORGANIZATION_CO2_REPORT_ERROR',

  START_ORGANIZATION_EUI_REPORT_SUCCESS = '@@analytics/START_ORGANIZATION_EUI_REPORT_SUCCESS',
  START_ORGANIZATION_EUI_REPORT_LOADING = '@@analytics/START_ORGANIZATION_EUI_REPORT_LOADING',
  START_ORGANIZATION_EUI_REPORT_ERROR = '@@analytics/START_ORGANIZATION_EUI_REPORT_ERROR',

  GET_ORGANIZATION_EUI_REPORT_SUCCESS = '@@analytics/GET_ORGANIZATION_EUI_REPORT_SUCCESS',
  GET_ORGANIZATION_EUI_REPORT_LOADING = '@@analytics/GET_ORGANIZATION_EUI_REPORT_LOADING',
  GET_ORGANIZATION_EUI_REPORT_ERROR = '@@analytics/GET_ORGANIZATION_EUI_REPORT_ERROR',

  START_ORGANIZATION_ENERGY_SUMMARY_REPORT_SUCCESS = '@@analytics/START_ORGANIZATION_ENERGY_SUMMARY_REPORT_SUCCESS',
  START_ORGANIZATION_ENERGY_SUMMARY_REPORT_LOADING = '@@analytics/START_ORGANIZATION_ENERGY_SUMMARY_REPORT_LOADING',
  START_ORGANIZATION_ENERGY_SUMMARY_REPORT_ERROR = '@@analytics/START_ORGANIZATION_ENERGY_SUMMARY_REPORT_ERROR',

  GET_ORGANIZATION_ENERGY_SUMMARY_REPORT_SUCCESS = '@@analytics/GET_ORGANIZATION_ENERGY_SUMMARY_REPORT_SUCCESS',
  GET_ORGANIZATION_ENERGY_SUMMARY_REPORT_LOADING = '@@analytics/GET_ORGANIZATION_ENERGY_SUMMARY_REPORT_LOADING',
  GET_ORGANIZATION_ENERGY_SUMMARY_REPORT_ERROR = '@@analytics/GET_ORGANIZATION_ENERGY_SUMMARY_REPORT_ERROR',

  START_ORGANIZATION_ENERGY_PDF_SUCCESS = '@@analytics/START_ORGANIZATION_ENERGY_PDF_SUCCESS',
  START_ORGANIZATION_ENERGY_PDF_LOADING = '@@analytics/START_ORGANIZATION_ENERGY_PDF_LOADING',
  START_ORGANIZATION_ENERGY_PDF_ERROR = '@@analytics/START_ORGANIZATION_ENERGY_PDF_ERROR',

  GET_ORGANIZATION_ENERGY_PDF_SUCCESS = '@@analytics/GET_ORGANIZATION_ENERGY_PDF_SUCCESS',
  GET_ORGANIZATION_ENERGY_PDF_LOADING = '@@analytics/GET_ORGANIZATION_ENERGY_PDF_LOADING',
  GET_ORGANIZATION_ENERGY_PDF_ERROR = '@@analytics/GET_ORGANIZATION_ENERGY_PDF_ERROR',

  START_ORG_NOH_SUCCESS = '@@analytics/START_ORG_NOH_SUCCESS',
  START_ORG_NOH_LOADING = '@@analytics/START_ORG_NOH_LOADING',
  START_ORG_NOH_ERROR = '@@analytics/START_ORG_NOH_ERROR',

  GET_ORG_NOH_SUCCESS = '@@analytics/GET_ORG_NOH_SUCCESS',
  GET_ORG_NOH_LOADING = '@@analytics/GET_ORG_NOH_LOADING',
  GET_ORG_NOH_ERROR = '@@analytics/GET_ORG_NOH_ERROR',

  START_MONTHLY_NOH_SUCCESS = '@@analytics/START_MONTHLY_NOH_SUCCESS',
  START_MONTHLY_NOH_LOADING = '@@analytics/START_MONTHLY_NOH_LOADING',
  START_MONTHLY_NOH_ERROR = '@@analytics/START_MONTHLY_NOH_ERROR',

  GET_MONTHLY_NOH_SUCCESS = '@@analytics/GET_MONTHLY_NOH_SUCCESS',
  GET_MONTHLY_NOH_LOADING = '@@analytics/GET_MONTHLY_NOH_LOADING',
  GET_MONTHLY_NOH_ERROR = '@@analytics/GET_MONTHLY_NOH_ERROR',

  GET_THERMOSTAT_COMPLAIANCE_REPORT_LOADING = '@@analytics/GET_THERMOSTAT_COMPLAIANCE_REPORT_LOADING',
  GET_THERMOSTAT_COMPLAIANCE_REPORT_SUCCESS = '@@analytics/GET_THERMOSTAT_COMPLAIANCE_REPORT_SUCCESS',
  GET_THERMOSTAT_COMPLAIANCE_REPORT_ERROR = '@@analytics/GET_THERMOSTAT_COMPLAIANCE_REPORT_ERROR',

  GET_ORG_THERMOSTAT_COMPLAIANCE_ASYNC_REPORT_LOADING = '@@analytics/GET_ORG_THERMOSTAT_COMPLAIANCE_ASYNC_REPORT_LOADING',
  GET_ORG_THERMOSTAT_COMPLAIANCE_ASYNC_REPORT_SUCCESS = '@@analytics/GET_ORG_THERMOSTAT_COMPLAIANCE_ASYNC_REPORT_SUCCESS',
  GET_ORG_THERMOSTAT_COMPLAIANCE_ASYNC_REPORT_ERROR = '@@analytics/GET_ORG_THERMOSTAT_COMPLAIANCE_ASYNC_REPORT_ERROR',

  GET_ORG_THERMOSTAT_COMPLAIANCE_REPORT_LOADING = '@@analytics/GET_ORG_THERMOSTAT_COMPLAIANCE_REPORT_LOADING',
  GET_ORG_THERMOSTAT_COMPLAIANCE_REPORT_SUCCESS = '@@analytics/GET_ORG_THERMOSTAT_COMPLAIANCE_REPORT_SUCCESS',
  GET_ORG_THERMOSTAT_COMPLAIANCE_REPORT_ERROR = '@@analytics/GET_ORG_THERMOSTAT_COMPLAIANCE_REPORT_ERROR',
}

const formatDateOrLatest = (date: Date | undefined, timeZone?: string) =>
  date ? formatDateForNotificationsAPI(date, timeZone) : 'latest';

const formatDateOrDates = (
  dateOrDates: Date | [Date, Date],
  timeZone?: string
) => {
  const dates = Array.isArray(dateOrDates) ? dateOrDates : [dateOrDates];
  return dates
    .map((date) => formatDateForNotificationsAPI(date, timeZone))
    .join('/');
};

export const getCoolerRecommendationsBySiteId = (
  siteId: string,
  date?: Date
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/report/cooler_recommendation/${siteId}/${formatDateOrLatest(date)}`,
  loading: { type: Actions.GET_COOLER_RECOMMENDATIONS_LOADING, siteId },
  success: { type: Actions.GET_COOLER_RECOMMENDATIONS_SUCCESS, siteId },
  error: { type: Actions.GET_COOLER_RECOMMENDATIONS_ERROR, siteId },
});

export const getHumidityRecommendationsBySiteId = (
  siteId: string,
  date?: Date
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/report/humidity_recommendation/${siteId}/${formatDateOrLatest(date)}`,
  loading: { type: Actions.GET_HUMIDITY_RECOMMENDATIONS_LOADING, siteId },
  success: { type: Actions.GET_HUMIDITY_RECOMMENDATIONS_SUCCESS, siteId },
  error: { type: Actions.GET_HUMIDITY_RECOMMENDATIONS_ERROR, siteId },
});

//TODO: THIS ENDPOINT DOESNT EXIST YET
export const getEnergyRecommendationsBySiteId = (
  siteId: string,
  date?: Date
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/report/energy_recommendation/${siteId}/${formatDateOrLatest(date)}`,
  loading: { type: Actions.GET_ENERGY_RECOMMENDATIONS_LOADING, siteId },
  success: { type: Actions.GET_ENERGY_RECOMMENDATIONS_SUCCESS, siteId },
  error: { type: Actions.GET_ENERGY_RECOMMENDATIONS_ERROR, siteId },
});

export const getEnergyReportBySiteId = (
  siteId: string,
  dateOrDates: Date | [Date, Date],
  timeZone?: string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/report/energy_report/${siteId}/${formatDateOrDates(
    dateOrDates,
    timeZone
  )}`,
  loading: {
    type: Actions.GET_ENERGY_REPORT_BY_SITE_ID_LOADING,
    siteId,
  },
  success: {
    type: Actions.GET_ENERGY_REPORT_BY_SITE_ID_SUCCESS,
    siteId,
  },
  error: {
    type: Actions.GET_ENERGY_REPORT_BY_SITE_ID_ERROR,
    siteId,
  },
});

export const getSitePeakDemandReport = (
  siteId: string,
  dateOrDates: Date | [Date, Date],
  reportType: SitePeakDemandReportType,
  timeZone?: string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/report/site_peak_demand_report/${siteId}/${formatDateOrDates(
    dateOrDates,
    timeZone
  )}`,
  loading: {
    type: Actions.GET_SITE_PEAK_DEMAND_REPORT_LOADING,
    siteId,
    reportType,
  },
  success: {
    type: Actions.GET_SITE_PEAK_DEMAND_REPORT_SUCCESS,
    siteId,
    reportType,
  },
  error: {
    type: Actions.GET_SITE_PEAK_DEMAND_REPORT_ERROR,
    siteId,
    reportType,
  },
});

export const getSchedulerSavings = (
  siteId: string,
  startDate: Date,
  endDate: Date
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/report/auto_scheduler_savings/${siteId}/${formatDateForNotificationsAPI(
    startDate
  )}/${formatDateForNotificationsAPI(endDate)}`,
  success: {
    type: Actions.GET_SCHEDULER_SAVINGS_BY_SITE_ID_SUCCESS,
    siteId,
  },
  loading: {
    type: Actions.GET_SCHEDULER_SAVINGS_BY_SITE_ID_LOADING,
    siteId,
  },
  error: {
    type: Actions.GET_SCHEDULER_SAVINGS_BY_SITE_ID_ERROR,
    siteId,
  },
});

export const getOrgTemperatureCompliance = (
  orgId: number,
  startDate: Date,
  endDate: Date,
  filters?: OrgTemperatureComplianceApiFilters,
  timeZone?: string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/report/org_compliance_by_site/${orgId}/${formatDateForNotificationsAPI(
    startDate,
    timeZone
  )}/${formatDateForNotificationsAPI(
    endDate,
    timeZone
  )}?${formatAPIFilters<OrgTemperatureComplianceApiFilters>(filters)}`,
  loading: {
    type: Actions.GET_TEMPERATURE_COMPLIANCE_BY_ORG_ID_LOADING,
    orgId,
  },
  success: {
    type: Actions.GET_TEMPERATURE_COMPLIANCE_BY_ORG_ID_SUCCESS,
    orgId,
  },
  error: { type: Actions.GET_TEMPERATURE_COMPLIANCE_BY_ORG_ID_ERROR, orgId },
});

export const getSiteTemperatureCompliance = (
  siteId: number,
  startDate: Date,
  endDate: Date,
  filters?: SiteTemperatureComplianceApiFilters,
  timeZone?: string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/report/site_compliance_by_sensor/${siteId}/${formatDateForNotificationsAPI(
    startDate,
    timeZone
  )}/${formatDateForNotificationsAPI(
    endDate,
    timeZone
  )}?${formatAPIFilters<SiteTemperatureComplianceApiFilters>(filters)}`,
  loading: {
    type: Actions.GET_TEMPERATURE_COMPLIANCE_BY_SITE_ID_LOADING,
    siteId,
  },
  success: {
    type: Actions.GET_TEMPERATURE_COMPLIANCE_BY_SITE_ID_SUCCESS,
    siteId,
  },
  error: { type: Actions.GET_TEMPERATURE_COMPLIANCE_BY_SITE_ID_ERROR, siteId },
});

export const getOrgEnergyConsumption = (
  orgId: string,
  startDate: Date,
  endDate: Date,
  siteIds: string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/report/site_energy_compare/${orgId}/${formatDateForNotificationsAPI(
    startDate
  )}/${formatDateForNotificationsAPI(endDate)}?site_id=${siteIds}`,
  success: {
    type: Actions.GET_ORGANIZATION_ENERGY_CONSUMPTION_SUCCESS,
  },
  loading: {
    type: Actions.GET_ORGANIZATION_ENERGY_CONSUMPTION_LOADING,
  },
  error: {
    type: Actions.GET_ORGANIZATION_ENERGY_CONSUMPTION_ERROR,
  },
});

export const getOrgEquipmentTypeEnergy = (
  orgId: number,
  startDate: Date,
  endDate: Date,
  siteIds: string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/report/equipment_type_energy_report/${orgId}/${formatDateForNotificationsAPI(
    startDate
  )}/${formatDateForNotificationsAPI(endDate)}?site_id=${siteIds}`,
  success: {
    type: Actions.GET_ORG_EQUIPMENT_TYPE_ENERGY_SUCCESS,
    orgId,
  },
  loading: {
    type: Actions.GET_ORG_EQUIPMENT_TYPE_ENERGY_LOADING,
    orgId,
  },
  error: {
    type: Actions.GET_ORG_EQUIPMENT_TYPE_ENERGY_ERROR,
    orgId,
  },
});

export const getSiteOverridesReport = (
  siteId: string | number,
  startDate: Date,
  endDate: Date
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/report/overrides_report/${siteId}/${formatDateForNotificationsAPI(
    startDate
  )}/${formatDateForNotificationsAPI(endDate)}`,
  loading: { type: Actions.GET_SITE_OVERRIDES_REPORT_LOADING, siteId },
  success: { type: Actions.GET_SITE_OVERRIDES_REPORT_SUCCESS, siteId },
  error: { type: Actions.GET_SITE_OVERRIDES_REPORT_ERROR, siteId },
});

export const getControlsSavings = (
  orgId: number,
  startDate: Date,
  endDate: Date,
  siteId?: number | string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/report/org_controls_savings/${orgId}/${formatDateForNotificationsAPI(
    startDate
  )}/${formatDateForNotificationsAPI(
    endDate
  )}?${formatAPIFilters<OrgControlsSavingsAnalyticsApiFilters>(
    siteId ? { site_id: siteId } : undefined
  )}`,
  loading: { type: Actions.GET_CONTROLS_SAVINGS_LOADING },
  success: { type: Actions.GET_CONTROLS_SAVINGS_SUCCESS },
  error: { type: Actions.GET_CONTROLS_SAVINGS_ERROR },
});

export const getSiteHvacOutlierReport = (
  siteId: string | number,
  startDate: Date,
  endDate: Date,
  timeZone: string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/report/hvac_outliers/${siteId}/${formatDateForNotificationsAPI(
    startDate,
    timeZone
  )}/${formatDateForNotificationsAPI(endDate, timeZone)}`,
  loading: { type: Actions.GET_HVAC_OUTLIERS_BY_SITE_ID_LOADING, siteId },
  success: { type: Actions.GET_HVAC_OUTLIERS_BY_SITE_ID_SUCCESS, siteId },
  error: { type: Actions.GET_HVAC_OUTLIERS_BY_SITE_ID_ERROR, siteId },
});

export const getEquipmentEnergyCompare = (
  orgId: number,
  startDate: Date,
  endDate: Date,
  timeZone: string,
  siteId?: number | string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/report/equipment_energy_compare/${orgId}/${formatDateForNotificationsAPI(
    startDate,
    timeZone
  )}/${formatDateForNotificationsAPI(
    endDate,
    timeZone
  )}?${formatAPIFilters<OrgControlsSavingsAnalyticsApiFilters>(
    siteId ? { site_id: siteId } : undefined
  )}`,
  loading: { type: Actions.GET_EQUIPMENT_ENERGY_COMPARE_LOADING },
  success: { type: Actions.GET_EQUIPMENT_ENERGY_COMPARE_SUCCESS },
  error: { type: Actions.GET_EQUIPMENT_ENERGY_COMPARE_ERROR },
});

export const getSiteOperatingHoursEnergy = (
  siteId: number,
  startDate: Date | string,
  endDate?: Date | string,
  ianaTimeZoneCode?: string
) => {
  let formattedDate =
    typeof startDate === 'string'
      ? startDate
      : formatToIsoDate(startDate, ianaTimeZoneCode);

  if (endDate) {
    formattedDate =
      typeof endDate === 'string'
        ? `${formattedDate}/${endDate}`
        : `${formattedDate}/${formatToIsoDate(endDate, ianaTimeZoneCode)}`;
  }

  let dateKey = '';
  if (!endDate) dateKey = formattedDate;

  return {
    type: 'API_GET',
    service: Service.analytics,
    path: `/report/site_off_hours_energy/${siteId}/${formattedDate}`,
    loading: {
      type: Actions.GET_SITE_OPERATING_HOURS_ENERGY_LOADING,
      siteId,
      dateKey,
    },
    success: {
      type: Actions.GET_SITE_OPERATING_HOURS_ENERGY_SUCCESS,
      siteId,
      dateKey,
    },
    error: {
      type: Actions.GET_SITE_OPERATING_HOURS_ENERGY_ERROR,
      siteId,
      dateKey,
    },
  };
};

export const getTemperatureRecommendationsBySiteId = (
  siteId: string,
  date?: Date
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/report/hvac_thermal_comfort/${siteId}/${formatDateOrLatest(date)}`,
  loading: {
    type: Actions.GET_TEMPERATURE_RECOMMENDATIONS_BY_SITE_LOADING,
    siteId,
  },
  success: {
    type: Actions.GET_TEMPERATURE_RECOMMENDATIONS_BY_SITE_SUCCESS,
    siteId,
  },
  error: {
    type: Actions.GET_TEMPERATURE_RECOMMENDATIONS_BY_SITE_ERROR,
    siteId,
  },
});

export const startOrganizationCO2Report = (
  organizationId: number | string,
  reportDate: string,
  siteIds: string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/async_report/org_co2_report/${organizationId}/${reportDate}?site_id=${siteIds}`,
  loading: {
    type: Actions.START_ORGANIZATION_CO2_REPORT_LOADING,
    organizationId,
    reportDate,
  },
  success: {
    type: Actions.START_ORGANIZATION_CO2_REPORT_SUCCESS,
    organizationId,
    reportDate,
  },
  error: {
    type: Actions.START_ORGANIZATION_CO2_REPORT_ERROR,
    organizationId,
    reportDate,
  },
});

export const getOrganizationCO2Report = (
  organizationId: number | string,
  timestamp: string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/report/org_co2_report/${organizationId}/${timestamp}`,
  loading: {
    type: Actions.GET_ORGANIZATION_CO2_REPORT_LOADING,
    organizationId,
    timestamp,
  },
  success: {
    type: Actions.GET_ORGANIZATION_CO2_REPORT_SUCCESS,
    organizationId,
    timestamp,
  },
  error: {
    type: Actions.GET_ORGANIZATION_CO2_REPORT_ERROR,
    organizationId,
    timestamp,
  },
});

export const startOrganizationEUIReport = (
  organizationId: number | string,
  reportDate: string,
  target: number | string,
  siteIds: string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/async_report/org_eui_report/${organizationId}/${reportDate}?target=${target}&site_id=${siteIds}`,
  loading: {
    type: Actions.START_ORGANIZATION_EUI_REPORT_LOADING,
    organizationId,
    reportDate,
    target,
  },
  success: {
    type: Actions.START_ORGANIZATION_EUI_REPORT_SUCCESS,
    organizationId,
    reportDate,
    target,
  },
  error: {
    type: Actions.START_ORGANIZATION_EUI_REPORT_ERROR,
    organizationId,
    reportDate,
    target,
  },
});

export const getOrganizationEUIReport = (
  organizationId: number | string,
  timestamp: string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/report/org_eui_report/${organizationId}/${timestamp}`,
  loading: {
    type: Actions.GET_ORGANIZATION_EUI_REPORT_LOADING,
    organizationId,
    timestamp,
  },
  success: {
    type: Actions.GET_ORGANIZATION_EUI_REPORT_SUCCESS,
    organizationId,
    timestamp,
  },
  error: {
    type: Actions.GET_ORGANIZATION_EUI_REPORT_ERROR,
    organizationId,
    timestamp,
  },
});

export const startOrganizationEnergySummary = (
  organizationId: number | string,
  reportDate: string,
  siteIds: string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/async_report/org_energy_summary_report/${organizationId}/${reportDate}?site_id=${siteIds}`,
  loading: {
    type: Actions.START_ORGANIZATION_ENERGY_SUMMARY_REPORT_LOADING,
    organizationId,
    reportDate,
  },
  success: {
    type: Actions.START_ORGANIZATION_ENERGY_SUMMARY_REPORT_SUCCESS,
    organizationId,
    reportDate,
  },
  error: {
    type: Actions.START_ORGANIZATION_ENERGY_SUMMARY_REPORT_ERROR,
    organizationId,
    reportDate,
  },
});

export const getOrganizationEnergySummary = (
  organizationId: number | string,
  timestamp: string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/report/org_energy_summary_report/${organizationId}/${timestamp}`,
  loading: {
    type: Actions.GET_ORGANIZATION_ENERGY_SUMMARY_REPORT_LOADING,
    organizationId,
    timestamp,
  },
  success: {
    type: Actions.GET_ORGANIZATION_ENERGY_SUMMARY_REPORT_SUCCESS,
    organizationId,
    timestamp,
  },
  error: {
    type: Actions.GET_ORGANIZATION_ENERGY_SUMMARY_REPORT_ERROR,
    organizationId,
    timestamp,
  },
});

export const startOrganizationEnergyPdf = (
  organizationId: number | string,
  reportDate: string,
  target: number | string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/async_report/org_energy_pdf/${organizationId}/${reportDate}?target=${target}`,
  loading: {
    type: Actions.START_ORGANIZATION_ENERGY_PDF_LOADING,
    organizationId,
    reportDate,
    target,
  },
  success: {
    type: Actions.START_ORGANIZATION_ENERGY_PDF_SUCCESS,
    organizationId,
    reportDate,
    target,
  },
  error: {
    type: Actions.START_ORGANIZATION_ENERGY_PDF_ERROR,
    organizationId,
    reportDate,
    target,
  },
});

export const getOrganizationEnergyPdf = (
  organizationId: number | string,
  timestamp: string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/report/org_energy_pdf/${organizationId}/${timestamp}`,
  loading: {
    type: Actions.GET_ORGANIZATION_ENERGY_PDF_LOADING,
    organizationId,
    timestamp,
  },
  success: {
    type: Actions.GET_ORGANIZATION_ENERGY_PDF_SUCCESS,
    organizationId,
    timestamp,
  },
  error: {
    type: Actions.GET_ORGANIZATION_ENERGY_PDF_ERROR,
    organizationId,
    timestamp,
  },
});

export const startOrgNOHReport = (
  organizationId: number | string,
  startDate: Date,
  endDate: Date,
  siteIds: string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/async_report/org_noh_report/${organizationId}/${formatDateForNotificationsAPI(
    startDate
  )}/${formatDateForNotificationsAPI(endDate)}?site_id=${siteIds}`,
  loading: {
    type: Actions.START_ORG_NOH_LOADING,
    organizationId,
    startDate,
    endDate,
  },
  success: {
    type: Actions.START_ORG_NOH_SUCCESS,
    organizationId,
    startDate,
    endDate,
  },
  error: {
    type: Actions.START_ORG_NOH_ERROR,
    organizationId,
    startDate,
    endDate,
  },
});

export const getOrgNOHReport = (
  organizationId: number | string,
  timestamp: string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/report/org_noh_report/${organizationId}/${timestamp}`,
  loading: {
    type: Actions.GET_ORG_NOH_LOADING,
    organizationId,
    timestamp,
  },
  success: {
    type: Actions.GET_ORG_NOH_SUCCESS,
    organizationId,
    timestamp,
  },
  error: {
    type: Actions.GET_ORG_NOH_ERROR,
    organizationId,
    timestamp,
  },
});

export const startMonthlyNOHReport = (
  organizationId: number | string,
  startDate: Date,
  endDate: Date,
  siteIds: string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/async_report/monthly_noh_report/${organizationId}/${formatDateForNotificationsAPI(
    startOfMonth(endDate)
  )}?site_id=${siteIds}`,
  loading: {
    type: Actions.START_MONTHLY_NOH_LOADING,
    organizationId,
    startDate,
    endDate,
  },
  success: {
    type: Actions.START_MONTHLY_NOH_SUCCESS,
    organizationId,
    startDate,
    endDate,
  },
  error: {
    type: Actions.START_MONTHLY_NOH_ERROR,
    organizationId,
    startDate,
    endDate,
  },
});

export const getMonthlyNOHReport = (
  organizationId: number | string,
  timestamp: string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/report/monthly_noh_report/${organizationId}/${timestamp}`,
  loading: {
    type: Actions.GET_MONTHLY_NOH_LOADING,
    organizationId,
    timestamp,
  },
  success: {
    type: Actions.GET_MONTHLY_NOH_SUCCESS,
    organizationId,
    timestamp,
  },
  error: {
    type: Actions.GET_MONTHLY_NOH_ERROR,
    organizationId,
    timestamp,
  },
});

export const getThermostatComplainaceReport = (
  siteId: number,
  reportDate: string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/report/thermostat_compliance_report/${siteId}/${reportDate}`,
  loading: {
    type: Actions.GET_THERMOSTAT_COMPLAIANCE_REPORT_LOADING,
    siteId,
    reportDate,
  },
  success: {
    type: Actions.GET_THERMOSTAT_COMPLAIANCE_REPORT_SUCCESS,
    siteId,
    reportDate,
  },
  error: {
    type: Actions.GET_THERMOSTAT_COMPLAIANCE_REPORT_ERROR,
    siteId,
    reportDate,
  },
});

export const getOrgThermostatComplianceReportKey = (
  orgId: number,
  startDate: Date,
  endDate: Date,
  siteIds: string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/async_report/org_thermostat_compliance/${orgId}/${formatDateForNotificationsAPI(
    startDate
  )}/${formatDateForNotificationsAPI(endDate)}?site_id=${siteIds}`,
  loading: {
    type: Actions.GET_ORG_THERMOSTAT_COMPLAIANCE_ASYNC_REPORT_LOADING,
    orgId,
  },
  success: {
    type: Actions.GET_ORG_THERMOSTAT_COMPLAIANCE_ASYNC_REPORT_SUCCESS,
    orgId,
  },
  error: {
    type: Actions.GET_ORG_THERMOSTAT_COMPLAIANCE_ASYNC_REPORT_ERROR,
    orgId,
  },
});

export const getOrgThermostatComplainaceReport = (
  orgId: number,
  report_date: string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/report/org_thermostat_compliance/${orgId}/${report_date}`,
  loading: {
    type: Actions.GET_ORG_THERMOSTAT_COMPLAIANCE_REPORT_LOADING,
    orgId,
  },
  success: {
    type: Actions.GET_ORG_THERMOSTAT_COMPLAIANCE_REPORT_SUCCESS,
    orgId,
  },
  error: {
    type: Actions.GET_ORG_THERMOSTAT_COMPLAIANCE_REPORT_ERROR,
    orgId,
  },
});

import { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IoIosMenu } from 'react-icons/io';
import useCurrentUser, { useOrganization } from '../../hooks/useCurrentUser';
import styles from './TopBarContainer.module.css';

import {
  AsyncImgLoader,
  HelpMenu,
  NewFeatureModal,
  NewFeaturePopup,
  TopBar,
  TopBarMenu,
  TopBarMenuItem,
  HighlightedNumber,
  DownloadIcon,
  NewPasswordPopup,
} from '@energybox/react-ui-library/dist/components';

import CompanyLogo from '@energybox/react-ui-library/dist/icons/CompanyLogo';
import {
  User as UserIcon,
  Organization as OrganizationIcon,
} from '@energybox/react-ui-library/dist/icons';
import { menuWithMobileStyles } from '@energybox/react-ui-library/dist/components/TopBar';
import {
  logout,
  getCurrentUser,
  toggleMobileMenu,
  dimissNewPasswordPopup,
} from '../../actions/app';
import { getCurrentOrganization } from '../../actions/organizations';
import { ApplicationState } from '../../reducers';
import history from '../../history';
import { global, classNames } from '@energybox/react-ui-library/dist/utils';
import { useLocation } from 'react-router-dom';
import { useDownloadStatus } from '../../hooks/reportworker/useDownloadStatus';
import {
  dismissNewFeature,
  getAttachmentUrl,
  getNewFeature,
  getNewFeatureVisited,
  viewNewFeature,
} from '../../actions/whatsNew';
import {
  FeaturesByMonth,
  FeatureStatus,
} from '@energybox/react-ui-library/dist/types/Feature';
import {
  ImgDom,
  Renderer,
} from '@energybox/react-ui-library/dist/types/ParsedHTML';
import {
  isAtlassianImg,
  render,
} from '@energybox/react-ui-library/dist/utils/parsedHTML';
import mixpanel from 'mixpanel-browser';
import useUsersById from '../../hooks/useUsersById';
import mixpanelEvents from '../../mixpanelEvents';
const { pathToRegexp } = require('path-to-regexp');
import * as Routes from '../../routes';
import { TopBarMenuItemProps } from '@energybox/react-ui-library/dist/components/TopBarMenuItem/TopBarMenuItem';

const TopBarContainer = ({ noNavigation }: { noNavigation?: boolean }) => {
  const trackable = () => {
    return !(
      process.env.REACT_APP_SERVICE_ENV &&
      process.env.REACT_APP_SERVICE_ENV.includes('az-china')
    );
  };

  const passwordUpdateEnforced = useSelector<
    ApplicationState,
    boolean | undefined
  >(({ app }) => app.passwordUpdateEnforced);
  const shouldShowNewPasswordPopup = useSelector<ApplicationState, boolean>(
    ({ app }) =>
      app.passwordUpdateEnforced === false && !app.newPasswordPopupDismissed
  );

  const dispatch = useDispatch();

  const location = useLocation();
  const [prevPage, setPrevPage] = useState<string | null>(null);
  const queryParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const isSimplyCheckView = queryParams.get('app') === 'simplycheck';
  const entrySource = queryParams.get('source') || 'browser';

  const currentOrganization = useOrganization();
  const user = useCurrentUser();
  const usersById = useUsersById();
  const isMobileMenuOpen = useSelector(
    ({ app }: ApplicationState) => app.isMobileMenuOpen
  );

  const saveFileId = useSelector(({ reportworker }: ApplicationState) =>
    reportworker.saveFileIds.filter((item) => item)
  );

  useDownloadStatus();

  useEffect(() => {
    if (!user) dispatch(getCurrentUser());
    if (!currentOrganization) dispatch(getCurrentOrganization());
    dispatch(getNewFeature());
  }, []);

  useEffect(() => {
    if (user && usersById) {
      const { id, organizationId, organizationTitle } = user;
      const userById = usersById[id];
      if (userById && trackable()) {
        const email = userById.email;
        const position = userById.position;

        mixpanel.identify(email);
        mixpanel.people.set({
          $email: email,
          USER_ID: id,
          ORG_ID: organizationId,
          ORG_TITLE: organizationTitle,
          POSITION: position,
        });
        mixpanel.track(mixpanelEvents.USER_VISIT, {
          source: entrySource, 
        });
      }
    }
  }, [user, usersById]);

  const routes = Object.entries(Routes).map(([key, value]) => {
    return { path: value };
  });

  const getPathPattern = (pathString) => {
    for (let route of routes) {
      const pattern = pathToRegexp(route.path);
      const match = pattern.exec(pathString);
      if (match) {
        return route.path;
      }
    }
    return undefined;
  };

  useEffect(() => {
    const currentPath = location.pathname;
    localStorage.setItem('nextEntrySource', currentPath);
    
    if (location && trackable()) {
      mixpanel.track(mixpanelEvents.PAGE_VISIT, {
        page: location.pathname,
        pattern: getPathPattern(location.pathname),
        source: entrySource,
        'Entry Point': prevPage || entrySource
      });
    }
    setPrevPage(currentPath);

    return () => {
      localStorage.setItem('pageReferrer', prevPage || 'none');
    };
  }, [location]);

  const [featurePopupShowing, showFeaturePopup] = useState(false);
  const [passwordPopupShowing, showPasswordPopup] = useState(false);
  const [modalShowing, setModalShowing] = useState(false);

  const featureTimerRef = useRef<NodeJS.Timeout | null>(null);
  const passwordTimerRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (user) dispatch(getNewFeatureVisited(user.id));
  }, [user]);

  const {
    newFeatures: features,
    featureStatus: viewStatus,
  }: {
    newFeatures?: FeaturesByMonth;
    featureStatus: FeatureStatus;
  } = useSelector(
    ({ whatsNew: { newFeatures, featureStatus } }: ApplicationState) => ({
      newFeatures,
      featureStatus,
    })
  );

  const cancelDelayedPopup = (ref: typeof featureTimerRef) => {
    if (ref.current) {
      clearTimeout(ref.current);
      ref.current = null;
    }
  };

  useEffect(() => {
    if (viewStatus === FeatureStatus.HAS_UPDATE) {
      if (user) cancelDelayedPopup(featureTimerRef);
      featureTimerRef.current = setTimeout(() => {
        showFeaturePopup(true);
        featureTimerRef.current = null;
      }, 2000);
      // Delay the pop-up for 2 seconds for better UX
    } else {
      showFeaturePopup(false);
      cancelDelayedPopup(featureTimerRef);
    }
    return () => cancelDelayedPopup(featureTimerRef);
  }, [viewStatus]);

  useEffect(() => {
    if (shouldShowNewPasswordPopup) {
      cancelDelayedPopup(passwordTimerRef);
      passwordTimerRef.current = setTimeout(() => {
        showPasswordPopup(true);
        passwordTimerRef.current = null;
      }, 2000);
      // Delay the pop-up for 2 seconds for better UX
    } else {
      showPasswordPopup(false);
      cancelDelayedPopup(passwordTimerRef);
    }
    return () => cancelDelayedPopup(passwordTimerRef);
  }, [shouldShowNewPasswordPopup]);

  const showModal = () => {
    if (user) {
      dispatch(viewNewFeature(user.id));
      setModalShowing(true);
    }
  };

  const dismissFeaturePopup = () => {
    dispatch(dismissNewFeature());
  };
  const dismissPasswordPopup = () => {
    dispatch(dimissNewPasswordPopup());
  };
  const dispatchLogout = () => {
    dispatch(logout());
  };

  const img: Renderer<ImgDom> = (dom) => {
    if (isAtlassianImg(dom)) {
      return (
        <AsyncImgLoader
          src={async () => getAttachmentUrl(dom)}
          width="100%"
          originalSize={{
            width: parseInt(dom.attributes['data-width']),
            height: parseInt(dom.attributes['data-height']),
          }}
        />
      );
    }
    return <img {...dom.attributes} />;
  };

  let notifyStatus = useSelector(
    ({ reportworker }: ApplicationState) => reportworker.notifyStatus
  );

  let showHideIcon = useSelector(
    ({ reportworker }: ApplicationState) => reportworker.toggleIcon
  );

  if (noNavigation) return <div />;

  const userMenuItem = (
    <>
      {!isSimplyCheckView && (
        <div className={menuWithMobileStyles.menuContainer}>
          <TopBarMenu
            key={'user-menu'}
            title={
              user ? `${user.firstName} ${user.lastName}` : global.NOT_AVAILABLE
            }
            icon={
              <UserIcon variant="tiny" color="var(--accent-base)" size={16} />
            }
          >
            {getUserMenuItems(user, dispatchLogout, passwordUpdateEnforced)}
          </TopBarMenu>
        </div>
      )}
    </>
  );

  const userMenuItemInMobile = (
    <>
      {!isSimplyCheckView && (
        <div className={menuWithMobileStyles.mobileMenuContainer}>
          <TopBarMenu
            key={'mobile-user-menu'}
            icon={
              <UserIcon variant="tiny" color="var(--accent-base)" size={16} />
            }
            noExpandIcon
            alignItemsRight
          >
            {getUserMenuItems(user, dispatchLogout, passwordUpdateEnforced)}
          </TopBarMenu>
        </div>
      )}
    </>
  );

  const menuItems: TopBarMenuItemProps[] = [
    {
      key: 'whatsnew',
      children: "What's New...",
      onSelect: () => showModal(),
      isVisible: !!features,
    },
    {
      key: 'license',
      children: 'Open Source Notices',
      onSelect: () => history.push('/license'),
    },
  ];

  return (
    <TopBar
      popupContainerClassName={styles.popupContainer}
      showPopup={featurePopupShowing || passwordPopupShowing}
      popup={
        <>
          {passwordPopupShowing && (
            <NewPasswordPopup
              onDismiss={dismissPasswordPopup}
              enforceDate={
                process.env.REACT_APP_ENFORCE_PASSWORD_CHANGE_DATE ||
                '2023-07-12'
              }
              changePasswordRoute={Routes.CHANGE_PASSWORD}
            />
          )}
          {featurePopupShowing && (
            <NewFeaturePopup
              rootClassName={styles.newFeaturePopup}
              onAction={showModal}
              onDismiss={dismissFeaturePopup}
            />
          )}
        </>
      }
    >
      <div>
        <button
          className={classNames(
            menuWithMobileStyles.mobileMenuButton,
            isMobileMenuOpen ? menuWithMobileStyles.glowingButton : null
          )}
          onClick={() => dispatch(toggleMobileMenu())}
        >
          <IoIosMenu
            className={menuWithMobileStyles.mobileMenuIcon}
            size="24"
          />
        </button>
        <CompanyLogo
          className={menuWithMobileStyles.companyLogoWithoutText}
          withText={false}
          width="18"
          height="18"
        />
      </div>
      {passwordUpdateEnforced ? (
        <div>
          {userMenuItem}
          {userMenuItemInMobile}
        </div>
      ) : (
        <div>
          <HelpMenu menuItems={menuItems} />

          {features && (
            <NewFeatureModal
              features={features}
              isModalShowing={modalShowing}
              onClose={() => setModalShowing(false)}
              render={render({ img })}
            />
          )}

          {user && (
            <div
              className={classNames(
                menuWithMobileStyles.menuContainer,
                styles.organizationMenuContainer
              )}
            >
              <OrganizationIcon color="var(--accent-base)" size={14} />
              <span className={styles.organizationMenuTitle}>
                {currentOrganization
                  ? currentOrganization.title
                  : global.NOT_AVAILABLE}
              </span>
            </div>
          )}

          {user && (
            <div className={menuWithMobileStyles.mobileMenuContainer}>
              <TopBarMenu
                key={'org-menu'}
                icon={<OrganizationIcon color="var(--accent-base)" size={16} />}
                noExpandIcon
                alignItemsRight
              >
                <TopBarMenuItem
                  key={'org-menu-item-1'}
                  className={menuWithMobileStyles.mobileStaticMenuItem}
                >
                  {currentOrganization
                    ? currentOrganization.title
                    : global.NOT_AVAILABLE}
                </TopBarMenuItem>
              </TopBarMenu>
            </div>
          )}

          {userMenuItem}

          {showHideIcon && (
            <DownloadIcon
              onClick={() => {
                history.push('/my-downloads');
              }}
              animated={notifyStatus}
            />
          )}

          {saveFileId.length !== 0 && (
            <HighlightedNumber
              value={saveFileId.length}
              className={styles.fileCounter}
              onClick={() => {
                history.push('/my-downloads');
              }}
            />
          )}

          {userMenuItemInMobile}
        </div>
      )}
    </TopBar>
  );
};

const getUserMenuItems = (user, dispatchLogout, passwordUpdateEnforced) => {
  const logoutItem = (
    <TopBarMenuItem
      key={'mobile-user-menu-item-4'}
      className={styles.link}
      onSelect={dispatchLogout}
    >
      Logout
    </TopBarMenuItem>
  );
  if (passwordUpdateEnforced) return [logoutItem];
  return [
    <TopBarMenuItem
      key={'mobile-user-menu-item-1'}
      className={styles.mobileStaticMenuItem}
    >
      {user ? `${user.firstName} ${user.lastName}` : global.NOT_AVAILABLE}
    </TopBarMenuItem>,
    <TopBarMenuItem
      key={'mobile-user-menu-item-2'}
      className={menuWithMobileStyles.mobileStaticMenuItem}
    >
      {user ? `${user.firstName} ${user.lastName}` : global.NOT_AVAILABLE}
    </TopBarMenuItem>,
    <TopBarMenuItem
      key={'my-downloads'}
      className={menuWithMobileStyles.link}
      onSelect={() => {
        history.push('/my-downloads');
      }}
    >
      My Downloads
    </TopBarMenuItem>,
    <TopBarMenuItem
      key={'mobile-user-menu-item-3'}
      className={menuWithMobileStyles.link}
      onSelect={() => history.push('/settings/change-password')}
    >
      Change Password
    </TopBarMenuItem>,
    logoutItem,
  ];
};

export default TopBarContainer;

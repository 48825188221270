import {
  EquipmentType,
  EquipmentTypeEnergyByGroupId,
  SiteOverridesReport,
} from '@energybox/react-ui-library/dist/types';
import {
  GetTemperatureComplianceByOrgResponse,
  GetTemperatureComplianceBySiteResponse,
  OrgTemperatureComplianceApiFilters,
  OrgTemperatureCompliaceSiteDetails,
  OrgTemperatureCompliaceSummary,
  SiteTemperatureCompliaceSensorDetails,
  SiteTemperatureCompliaceSummary,
  SiteTemperatureComplianceApiFilters,
  SensorTemperatureComplianceData,
  SensorTemperatureComplianceLevel,
} from '@energybox/react-ui-library/dist/types/Analytics';
import {
  endOfMonth,
  format,
  intlFormat,
  parse,
  startOfMonth,
  subMonths,
} from 'date-fns';
import { genericDateFormat } from '@energybox/react-ui-library/dist/utils';
import { pathOr } from 'ramda';
import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getControlsSavings,
  getOrganizationCO2Report,
  getOrganizationEnergyPdf,
  getOrganizationEnergySummary,
  getOrganizationEUIReport,
  getOrgEquipmentTypeEnergy,
  getOrgTemperatureCompliance,
  getSiteOverridesReport,
  getSiteTemperatureCompliance,
  getOrgNOHReport,
  getMonthlyNOHReport,
  startOrganizationCO2Report,
  startOrganizationEnergyPdf,
  startOrganizationEnergySummary,
  startOrganizationEUIReport,
  startOrgNOHReport,
  startMonthlyNOHReport,
} from '../actions/analytics';
import { ErrorMessage } from '../containers/MultiSiteTiles/TemperatureComplianceTileContainer/TemperatureComplianceTileContainer';
import { ApplicationState } from '../reducers';
import { sanitizeApiLoadingState } from '../util';
import { useOrganizationId } from './useCurrentUser';
import { useTimeFilter } from './useFilters';
import { useSite } from './useSites';
import { useApiDispatchWithDependencies } from './utils';
import { useSiteTimezone } from './useSites';

const nullCompliance = {
  compliance_level: SensorTemperatureComplianceLevel.NoData,
};

type FlattenedSensorData = SensorTemperatureComplianceData[];

export type SiteCompliances = {
  siteId: string;
} & OrgTemperatureCompliaceSiteDetails;
export type SensorCompliances = {
  sensorId: string;
  flattenedData: FlattenedSensorData;
} & SiteTemperatureCompliaceSensorDetails;

export type OrgTemperatureComplianceStatus = {
  isLoading: boolean;
  errorMessage?: string;
  siteCompliances: SiteCompliances[];
  orgSummary?: OrgTemperatureCompliaceSummary;
  availableEquipmentTypes: Partial<EquipmentType>[];
};

export type SiteTemperatureComplianceStatus = {
  isLoading: boolean;
  errorMessage?: string;
  sensorCompliances: SensorCompliances[];
  siteSummary?: SiteTemperatureCompliaceSummary;
  availableEquipmentTypes: Partial<EquipmentType>[];
};

export const useTemperatureComplianceByOrgId = ({
  site_id,
  equipment_type_id,
}: OrgTemperatureComplianceApiFilters): OrgTemperatureComplianceStatus => {
  const dispatch = useDispatch();

  /** SELECTORS START */
  const orgId = useOrganizationId();
  const { timePeriod } = useTimeFilter();
  const site = useSite(Array.isArray(site_id) ? site_id[0] : site_id);
  const orgTemperatureCompliance = useSelector<
    ApplicationState,
    GetTemperatureComplianceByOrgResponse['data'] | undefined
  >(({ analytics }) => {
    const equipmentComplianceByOrg = analytics.temperatureCompliance.byOrgId;
    if (orgId !== undefined) {
      return equipmentComplianceByOrg[orgId]?.data;
    }
    return undefined;
  });
  const shouldBeLoading = useSelector(({ analytics }: ApplicationState) => {
    return pathOr(
      undefined,
      ['temperatureCompliance', 'isLoadingByOrgId', orgId],
      analytics
    );
  });

  /* SITE FILTER BASED ON USER FOR EACH ORG */
  const sites = useSelector((state: ApplicationState) => state.sites.sites);
  site_id = sites?.map((site) => site.id).join(',') || '';

  /** SELECTORS END */

  useEffect(() => {
    if (orgId === undefined) return;
    if (site_id === '') return;
    dispatch(
      getOrgTemperatureCompliance(
        orgId,
        timePeriod.fromDate,
        timePeriod.toDate,
        {
          site_id,
          equipment_type_id,
        },
        site?.timeZone
      )
    );
  }, [orgId, timePeriod, site_id, equipment_type_id, site?.timeZone]);

  const isLoading = sanitizeApiLoadingState(shouldBeLoading);

  let result: OrgTemperatureComplianceStatus;

  if (
    isLoading ||
    !orgTemperatureCompliance ||
    Object.values(orgTemperatureCompliance.by_site).length === 0
  )
    result = {
      isLoading,
      errorMessage: ErrorMessage.NO_DATA,
      siteCompliances: [],
      availableEquipmentTypes: [],
    };
  else {
    const siteCompliances = Object.entries(
      orgTemperatureCompliance.by_site
    ).map(([siteId, details]) => ({
      siteId,
      ...details,
    }));
    const orgSummary = Object.values(orgTemperatureCompliance.by_org)[0];
    const availableEquipmentTypes = Object.entries(
      orgTemperatureCompliance.equipment_type_dict
    ).map(([id, title]) => ({
      id: parseInt(id),
      title,
    }));

    result = {
      isLoading: false,
      siteCompliances,
      orgSummary,
      availableEquipmentTypes,
    };
  }

  return result;
};

export const useTemperatureComplianceBySiteId = (
  siteId: string,
  { equipment_type_id }: SiteTemperatureComplianceApiFilters
): SiteTemperatureComplianceStatus => {
  const dispatch = useDispatch();

  /** SELECTORS START */
  const { timePeriod } = useTimeFilter();
  const site = useSite(siteId);
  const siteTemperatureCompliance = useSelector<
    ApplicationState,
    GetTemperatureComplianceBySiteResponse['data'] | undefined
  >(({ analytics }) => {
    const equipmentComplianceBySiteId =
      analytics.temperatureCompliance.bySiteId;
    if (siteId !== undefined) {
      return equipmentComplianceBySiteId[siteId]?.data;
    }
    return undefined;
  });
  const shouldBeLoading = useSelector(({ analytics }: ApplicationState) => {
    return pathOr(
      undefined,
      ['temperatureCompliance', 'isLoadingBySiteId', siteId],
      analytics
    );
  });
  /** SELECTORS END */

  useEffect(() => {
    if (siteId === undefined) return;
    dispatch(
      getSiteTemperatureCompliance(
        parseInt(siteId),
        timePeriod.fromDate,
        timePeriod.toDate,
        {
          equipment_type_id,
        },
        site?.timeZone
      )
    );
  }, [siteId, timePeriod, equipment_type_id, site?.timeZone]);

  const isLoading = sanitizeApiLoadingState(shouldBeLoading);

  let result: SiteTemperatureComplianceStatus;

  if (
    isLoading ||
    !siteTemperatureCompliance ||
    Object.values(siteTemperatureCompliance.by_sensor).length === 0
  )
    result = {
      isLoading,
      errorMessage: ErrorMessage.NO_DATA,
      sensorCompliances: [],
      availableEquipmentTypes: [],
    };
  else {
    const sensorCompliances = Object.entries(
      siteTemperatureCompliance.by_sensor
    ).map(([sensorId, details]) => {
      const { hourly_avg } = details;

      let flattenedData: FlattenedSensorData = [];

      for (
        let dt = new Date(timePeriod.fromDate);
        dt <= timePeriod.toDate;
        dt.setDate(dt.getDate() + 1)
      ) {
        const dateString = genericDateFormat(dt, 'yyyy-MM-dd');
        const oneDay = hourly_avg[dateString];
        flattenedData = flattenedData.concat(
          oneDay
            ? (() => {
                const allHours = [...Array(24).keys()];
                return allHours.map<SensorTemperatureComplianceData>((hour) => {
                  return oneDay['' + hour] || nullCompliance;
                });
              })()
            : Array(24).fill(nullCompliance)
        );
      }

      return {
        sensorId,
        flattenedData,
        ...details,
      };
    });
    const siteSummary = Object.values(siteTemperatureCompliance.by_site)[0];
    const availableEquipmentTypes = Object.entries(
      siteTemperatureCompliance.equipment_type_dict
    ).map(([id, title]) => ({
      id: parseInt(id),
      title,
    }));

    result = {
      isLoading: false,
      sensorCompliances,
      siteSummary,
      availableEquipmentTypes,
    };
  }

  return result;
};

export const useOrgEquipmentTypeEnergyByGroupId = (): {
  isLoading: boolean;
  equipmentEnergy: EquipmentTypeEnergyByGroupId;
} => {
  /*********** SELECTORS ************/
  const orgId = useOrganizationId();

  const report = useSelector(({ analytics }: ApplicationState) => {
    const equipmentEnergy = pathOr(
      undefined,
      ['byGroupId'],
      analytics.equipmentTypeEnergy
    );
    const isLoading = pathOr(
      undefined,
      ['isLoading'],
      analytics.equipmentTypeEnergy
    );
    return {
      equipmentEnergy,
      isLoading,
    };
  });
  const { timePeriod } = useTimeFilter();

  /* SITE FILTER BASED ON USER FOR EACH ORG */
  const sites = useSelector((state: ApplicationState) => state.sites.sites);
  const siteIdsStr = sites?.map((site) => site.id).join(',') || '';

  /*********** API LOGIC ************/
  const dispatchCallback = useMemo(() => {
    if (!orgId) return null;
    if (siteIdsStr === '') return null;
    return () => {
      const result = getOrgEquipmentTypeEnergy(
        orgId,
        timePeriod.fromDate,
        timePeriod.toDate,
        siteIdsStr
      );
      return result;
    };
  }, [timePeriod, orgId, siteIdsStr]);

  useApiDispatchWithDependencies(report.isLoading, dispatchCallback);

  return report;
};

export function useSiteOverridesReport(siteId: string) {
  const dispatch = useDispatch();
  const { timePeriod } = useTimeFilter();

  const getSiteOverrides = useCallback(
    (siteId: string | number, startDate: Date, endDate: Date) =>
      dispatch(getSiteOverridesReport(siteId, startDate, endDate)),
    [dispatch]
  );

  const siteOverridesReport = useSelector(
    ({ analytics }: ApplicationState): SiteOverridesReport | undefined => {
      return analytics.siteOverridesReport.bySiteId[siteId];
    }
  );

  const siteOverridesReportIsLoading = useSelector(
    ({ analytics }: ApplicationState) => {
      return analytics.siteOverridesReport.isLoading;
    }
  );

  useEffect(() => {
    getSiteOverrides(siteId, timePeriod.fromDate, timePeriod.toDate);
  }, [siteId, timePeriod, getSiteOverrides]);

  return {
    isLoading: siteOverridesReportIsLoading,
    siteOverridesReport,
  };
}

export const useControlsSavingsFetch = (siteId?: number | string) => {
  const dispatch = useDispatch();
  const { timePeriod } = useTimeFilter();
  const orgId = useOrganizationId();

  // *********** Effects ********** //
  useEffect(() => {
    if (orgId) {
      dispatch(
        getControlsSavings(
          orgId,
          timePeriod.fromDate,
          timePeriod.toDate,
          siteId
        )
      );
    }
  }, [dispatch, orgId, siteId, timePeriod]);
};

export const useOrganizationCO2Report = (year: number) => {
  const dispatch = useDispatch();
  const orgId = useOrganizationId();

  const { co2Report, reportKey, isLoading, reportVersion, reportFetchTime } =
    useSelector(({ analytics }: ApplicationState) => {
      const co2Report = pathOr(
        undefined,
        [orgId, 'data'],
        analytics.organizationCO2Report
      );
      const isLoading = pathOr(
        undefined,
        [orgId, 'isLoading'],
        analytics.organizationCO2Report
      );
      const reportKey = pathOr(
        undefined,
        [orgId, 'reportKey'],
        analytics.organizationCO2Report
      );
      const reportVersion = pathOr(
        0,
        [orgId, 'reportVersion'],
        analytics.organizationCO2Report
      );
      const reportFetchTime = pathOr(
        undefined,
        [orgId, 'reportFetchTime'],
        analytics.organizationCO2Report
      );
      return {
        co2Report,
        reportKey,
        isLoading,
        reportVersion,
        reportFetchTime,
      };
    });

  /* SITE FILTER BASED ON USER FOR EACH ORG */
  const sites = useSelector((state: ApplicationState) => state.sites.sites);
  const siteIdsStr = sites?.map((site) => site.id).join(',') || '';

  useEffect(() => {
    if (
      orgId !== undefined &&
      reportKey === undefined &&
      !isLoading &&
      reportVersion === 0 &&
      siteIdsStr !== ''
    ) {
      const now = new Date();
      const month =
        year === now.getFullYear() ? format(subMonths(now, 1), 'MM') : 12;
      dispatch(
        startOrganizationCO2Report(orgId, `${year}-${month}-01`, siteIdsStr)
      );
    }
  }, [orgId, year, isLoading, co2Report, siteIdsStr]);

  useEffect(() => {
    if (orgId !== undefined && reportKey !== undefined && reportVersion < 1) {
      setTimeout(() => {
        dispatch(getOrganizationCO2Report(orgId, reportKey));
      }, 1000);
    }
  }, [orgId, reportKey, co2Report, reportVersion, reportFetchTime]);

  return {
    reportKey,
    isLoading,
    data: co2Report,
    isReportReady: reportVersion == 1,
  };
};

export const useOrganizationEUIReport = (year: number) => {
  const dispatch = useDispatch();
  const orgId = useOrganizationId();

  const {
    euiReport,
    reportKey,
    isLoading,
    reportVersion,
    reportFetchTime,
    targetLevel,
  } = useSelector(({ analytics }: ApplicationState) => {
    const euiReport = pathOr(
      undefined,
      [orgId, 'data'],
      analytics.organizationEUIReport
    );
    const isLoading = pathOr(
      undefined,
      [orgId, 'isLoading'],
      analytics.organizationEUIReport
    );
    const reportKey = pathOr(
      undefined,
      [orgId, 'reportKey'],
      analytics.organizationEUIReport
    );
    const reportVersion = pathOr(
      0,
      [orgId, 'reportVersion'],
      analytics.organizationEUIReport
    );
    const reportFetchTime = pathOr(
      undefined,
      [orgId, 'reportFetchTime'],
      analytics.organizationEUIReport
    );
    const targetLevel = pathOr(
      undefined,
      [orgId, 'targetLevel'],
      analytics.organizationEUIReport
    );
    return {
      euiReport,
      reportKey,
      isLoading,
      reportVersion,
      reportFetchTime,
      targetLevel,
    };
  });

  /* SITE FILTER BASED ON USER FOR EACH ORG */
  const sites = useSelector((state: ApplicationState) => state.sites.sites);
  const siteIdsStr = sites?.map((site) => site.id).join(',') || '';

  useEffect(() => {
    if (
      orgId !== undefined &&
      reportKey === undefined &&
      !isLoading &&
      reportVersion === 0 &&
      siteIdsStr !== ''
    ) {
      const now = new Date();
      const month =
        year === now.getFullYear() ? format(subMonths(now, 1), 'MM') : 12;
      dispatch(
        startOrganizationEUIReport(
          orgId,
          `${year}-${month}-01`,
          500,
          siteIdsStr
        )
      );
    }
  }, [orgId, year, isLoading, euiReport, reportVersion, siteIdsStr]);

  useEffect(() => {
    if (orgId !== undefined && reportKey !== undefined && reportVersion < 1) {
      setTimeout(() => {
        dispatch(getOrganizationEUIReport(orgId, reportKey));
      }, 1000);
    }
  }, [orgId, reportKey, euiReport, reportVersion, reportFetchTime]);

  return {
    data: euiReport,
    reportKey,
    isLoading,
    isReportReady: reportVersion == 1,
    targetLevel,
  };
};

export const useOrganizationEnergySummary = (year: number) => {
  const dispatch = useDispatch();
  const orgId = useOrganizationId();

  const { report, reportKey, isLoading, reportVersion, reportFetchTime } =
    useSelector(({ analytics }: ApplicationState) => {
      const report = pathOr(
        undefined,
        [orgId, 'data'],
        analytics.organizationEnergySummaryReport
      );
      const isLoading = pathOr(
        undefined,
        [orgId, 'isLoading'],
        analytics.organizationEnergySummaryReport
      );
      const reportKey = pathOr(
        undefined,
        [orgId, 'reportKey'],
        analytics.organizationEnergySummaryReport
      );
      const reportVersion = pathOr(
        0,
        [orgId, 'reportVersion'],
        analytics.organizationEnergySummaryReport
      );
      const reportFetchTime = pathOr(
        undefined,
        [orgId, 'reportFetchTime'],
        analytics.organizationEnergySummaryReport
      );
      return {
        report,
        reportKey,
        isLoading,
        reportVersion,
        reportFetchTime,
      };
    });

  /* SITE FILTER BASED ON USER FOR EACH ORG */
  const sites = useSelector((state: ApplicationState) => state.sites.sites);
  const siteIdsStr = sites?.map((site) => site.id).join(',') || '';

  useEffect(() => {
    if (
      orgId !== undefined &&
      reportKey === undefined &&
      !isLoading &&
      reportVersion === 0 &&
      siteIdsStr !== ''
    ) {
      const now = new Date();
      const month =
        year === now.getFullYear() ? format(subMonths(now, 1), 'MM') : 12;
      dispatch(
        startOrganizationEnergySummary(orgId, `${year}-${month}-01`, siteIdsStr)
      );
    }
  }, [orgId, year, isLoading, report, siteIdsStr]);

  useEffect(() => {
    if (orgId !== undefined && reportKey !== undefined && reportVersion < 1) {
      setTimeout(() => {
        dispatch(getOrganizationEnergySummary(orgId, reportKey));
      }, 1000);
    }
  }, [orgId, reportKey, report, reportVersion, reportFetchTime]);

  return {
    data: report,
    reportKey,
    isLoading,
    isReportReady: reportVersion == 1,
  };
};

export const useOrganizationEnergyPdf = (year: number) => {
  const dispatch = useDispatch();
  const orgId = useOrganizationId();

  const {
    report,
    reportKey,
    isLoading,
    reportVersion,
    reportFetchTime,
    targetLevel,
  } = useSelector(({ analytics }: ApplicationState) => {
    const report = pathOr(
      undefined,
      [orgId, 'data'],
      analytics.organizationEnergyPdf
    );
    const isLoading = pathOr(
      undefined,
      [orgId, 'isLoading'],
      analytics.organizationEnergyPdf
    );
    const reportKey = pathOr(
      undefined,
      [orgId, 'reportKey'],
      analytics.organizationEnergyPdf
    );
    const reportVersion = pathOr(
      0,
      [orgId, 'reportVersion'],
      analytics.organizationEnergyPdf
    );
    const reportFetchTime = pathOr(
      undefined,
      [orgId, 'reportFetchTime'],
      analytics.organizationEnergyPdf
    );
    const targetLevel = pathOr(
      undefined,
      [orgId, 'targetLevel'],
      analytics.organizationEnergyPdf
    );
    return {
      report,
      reportKey,
      isLoading,
      reportVersion,
      reportFetchTime,
      targetLevel,
    };
  });

  useEffect(() => {
    if (
      orgId !== undefined &&
      reportKey === undefined &&
      !isLoading &&
      reportVersion === 0
    ) {
      dispatch(startOrganizationEnergyPdf(orgId, `${year}-01-01`, 500));
    }
  }, [orgId, year, isLoading, report]);

  useEffect(() => {
    if (orgId !== undefined && reportKey !== undefined && reportVersion < 1) {
      setTimeout(() => {
        dispatch(getOrganizationEnergyPdf(orgId, reportKey));
      }, 1000);
    }
  }, [orgId, reportKey, report, reportVersion, reportFetchTime]);

  return {
    data: report,
    reportKey,
    isLoading,
    isReportReady: reportVersion == 1,
    targetLevel,
  };
};

export const useOrgNOHReport = (siteId?: number | string) => {
  const dispatch = useDispatch();
  const orgId = useOrganizationId();
  const { timePeriod } = useTimeFilter();
  const { fromDate, toDate } = timePeriod;
  const { report, reportKey, isLoading, reportVersion, reportFetchTime } =
    useSelector(({ analytics }: ApplicationState) => {
      const report = pathOr(
        undefined,
        [orgId, 'data'],
        analytics.organizationNonOperatingHour
      );
      const isLoading = pathOr(
        undefined,
        [orgId, 'isLoading'],
        analytics.organizationNonOperatingHour
      );
      const reportKey = pathOr(
        undefined,
        [orgId, 'reportKey'],
        analytics.organizationNonOperatingHour
      );
      const reportVersion = pathOr(
        0,
        [orgId, 'reportVersion'],
        analytics.organizationNonOperatingHour
      );
      const reportFetchTime = pathOr(
        undefined,
        [orgId, 'reportFetchTime'],
        analytics.organizationNonOperatingHour
      );
      return {
        report,
        reportKey,
        isLoading,
        reportVersion,
        reportFetchTime,
      };
    });

  /* SITE FILTER BASED ON USER FOR EACH ORG */
  const sites = useSelector((state: ApplicationState) => state.sites.sites);
  const siteIdsStr = sites?.map((site) => site.id).join(',') || '';

  /** SELECTORS END */

  useEffect(() => {
    if (
      orgId !== undefined &&
      reportKey === undefined &&
      !isLoading &&
      reportVersion === 0 &&
      fromDate !== undefined &&
      toDate !== undefined &&
      siteIdsStr !== ''
    ) {
      dispatch(startOrgNOHReport(orgId, fromDate, toDate, siteIdsStr));
    }
  }, [orgId, fromDate, toDate, isLoading, report, siteIdsStr]);

  useEffect(() => {
    if (
      orgId !== undefined &&
      fromDate !== undefined &&
      toDate !== undefined &&
      siteIdsStr !== ''
    ) {
      dispatch(startOrgNOHReport(orgId, fromDate, toDate, siteIdsStr));
    }
  }, [orgId, fromDate, toDate, siteIdsStr]);

  useEffect(() => {
    if (orgId !== undefined && reportKey !== undefined && reportVersion < 1) {
      setTimeout(() => {
        dispatch(getOrgNOHReport(orgId, reportKey));
      }, 1000);
    }
  }, [orgId, reportKey, report, reportVersion, reportFetchTime]);

  return {
    data: report,
    reportKey,
    isLoading,
    isReportReady: reportVersion == 1,
  };
};

export const useMonthlyNOHReport = (fromDate, toDate) => {
  const dispatch = useDispatch();
  const orgId = useOrganizationId();

  const { report, reportKey, isLoading, reportVersion, reportFetchTime } =
    useSelector(({ analytics }: ApplicationState) => {
      const report = pathOr(
        undefined,
        [orgId, 'data'],
        analytics.monthlyNonOperatingHour
      );
      const isLoading = pathOr(
        undefined,
        [orgId, 'isLoading'],
        analytics.monthlyNonOperatingHour
      );
      const reportKey = pathOr(
        undefined,
        [orgId, 'reportKey'],
        analytics.monthlyNonOperatingHour
      );
      const reportVersion = pathOr(
        0,
        [orgId, 'reportVersion'],
        analytics.monthlyNonOperatingHour
      );
      const reportFetchTime = pathOr(
        undefined,
        [orgId, 'reportFetchTime'],
        analytics.monthlyNonOperatingHour
      );
      return {
        report,
        reportKey,
        isLoading,
        reportVersion,
        reportFetchTime,
      };
    });

  /* SITE FILTER BASED ON USER FOR EACH ORG */
  const sites = useSelector((state: ApplicationState) => state.sites.sites);
  const siteIdsStr = sites?.map((site) => site.id).join(',') || '';

  useEffect(() => {
    if (
      orgId !== undefined &&
      reportKey === undefined &&
      !isLoading &&
      reportVersion === 0 &&
      siteIdsStr !== ''
    ) {
      dispatch(
        startMonthlyNOHReport(
          orgId,
          startOfMonth(fromDate),
          endOfMonth(toDate),
          siteIdsStr
        )
      );
    }
  }, [orgId, fromDate, toDate, isLoading, report, siteIdsStr]);

  useEffect(() => {
    if (orgId !== undefined && reportKey !== undefined && reportVersion < 1) {
      setTimeout(() => {
        dispatch(getMonthlyNOHReport(orgId, reportKey));
      }, 1000);
    }
  }, [orgId, reportKey, report, reportVersion, reportFetchTime]);

  return {
    data: report,
    reportKey,
    isLoading,
    isReportReady: reportVersion == 1,
  };
};

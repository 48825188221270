import {
  Equipment,
  EquipmentApiFilter,
  SensorType,
} from '@energybox/react-ui-library/dist/types';
import { formatDateForNotificationsAPI } from '@energybox/react-ui-library/dist/utils';
import { Service } from '../config';
import { fetchApi } from '../utils/fetchApi';
import { formatAPIFilters } from '../util';

export enum Actions {
  CLEAR_FILTERED_EQUIPMENT_LIST = '@@equipment/CLEAR_FILTERED_EQUIPMENT_LIST',

  GET_EQUIPMENT_SUCCESS = '@@equipment/GET_EQUIPMENT_SUCCESS',
  GET_EQUIPMENT_LOADING = '@@equipment/GET_EQUIPMENT_LOADING',
  GET_EQUIPMENT_ERROR = '@@equipment/GET_EQUIPMENT_ERROR',

  GET_EQUIPMENT_BY_ID_SUCCESS = '@@equipment/GET_EQUIPMENT_BY_ID_SUCCESS',
  GET_EQUIPMENT_BY_ID_LOADING = '@@equipment/GET_EQUIPMENT_BY_ID_LOADING',
  GET_EQUIPMENT_BY_ID_ERROR = '@@equipment/GET_EQUIPMENT_BY_ID_ERROR',

  GET_EQUIPMENT_BY_SITE_ID_ERROR = '@@equipment/GET_EQUIPMENT_BY_SITE_ID_ERROR',
  GET_EQUIPMENT_BY_SITE_ID_LOADING = '@@equipment/GET_EQUIPMENT_BY_SITE_ID_LOADING',
  GET_EQUIPMENT_BY_SITE_ID_SUCCESS = '@@equipment/GET_EQUIPMENT_BY_SITE_ID_SUCCESS',

  GET_EQUIPMENT_BY_SPACE_ID_ERROR = '@@equipment/GET_EQUIPMENT_BY_SPACE_ID_ERROR',
  GET_EQUIPMENT_BY_SPACE_ID_LOADING = '@@equipment/GET_EQUIPMENT_BY_SPACE_ID_LOADING',
  GET_EQUIPMENT_BY_SPACE_ID_SUCCESS = '@@equipment/GET_EQUIPMENT_BY_SPACE_ID_SUCCESS',

  GET_ALL_EQUIPMENT_COUNT_PER_GROUP_ID_SUCCESS = '@@equipment/GET_ALL_EQUIPMENT_COUNT_PER_GROUP_ID_SUCCESS',
  GET_ALL_EQUIPMENT_COUNT_PER_GROUP_ID_LOADING = '@@equipment/GET_ALL_EQUIPMENT_COUNT_PER_GROUP_ID_LOADING',
  GET_ALL_EQUIPMENT_COUNT_PER_GROUP_ID_ERROR = '@@equipment/GET_ALL_EQUIPMENT_COUNT_PER_GROUP_ID_ERROR',

  GET_HVAC_CONTROL_EQUIPMENT_COUNT_SUCCESS = '@@equipment/GET_HVAC_CONTROL_EQUIPMENT_COUNT_SUCCESS',
  GET_HVAC_CONTROL_EQUIPMENT_COUNT_LOADING = '@@equipment/GET_HVAC_CONTROL_EQUIPMENT_COUNT_LOADING',
  GET_HVAC_CONTROL_EQUIPMENT_COUNT_ERROR = '@@equipment/GET_HVAC_CONTROL_EQUIPMENT_COUNT_ERROR',

  GET_EQUIPMENT_PERFORMANCE_BY_GROUP_SUCCESS = '@@equipment/GET_EQUIPMENT_PERFORMANCE_BY_GROUP_SUCCESS',
  GET_EQUIPMENT_PERFORMANCE_BY_GROUP_LOADING = '@@equipment/GET_EQUIPMENT_PERFORMANCE_BY_GROUP_LOADING',
  GET_EQUIPMENT_PERFORMANCE_BY_GROUP_ERROR = '@@equipment/GET_EQUIPMENT_PERFORMANCE_BY_GROUP_ERROR',
  CLEAR_EQUIPMENT_PERFORMANCE = '@@equipment/CLEAR_EQUIPMENT_PERFORMANCE',

  GET_EQUIPMENT_PERFORMANCE_ASYNC_REPORT_ID_SUCCESS = '@@equipment/GET_EQUIPMENT_PERFORMANCE_ASYNC_REPORT_ID_SUCCESS',
  GET_EQUIPMENT_PERFORMANCE_ASYNC_REPORT_ID_LOADING = '@@equipment/GET_EQUIPMENT_PERFORMANCE_ASYNC_REPORT_ID_LOADING',
  GET_EQUIPMENT_PERFORMANCE_ASYNC_REPORT_ID_ERROR = '@@equipment/GET_EQUIPMENT_PERFORMANCE_ASYNC_REPORT_ID_ERROR',

  GET_EQUIPMENT_PERFORMANCE_ASYNC_REPORT_SUCCESS = '@@equipment/GET_EQUIPMENT_PERFORMANCE_ASYNC_REPORT_SUCCESS',
  GET_EQUIPMENT_PERFORMANCE_ASYNC_REPORT_LOADING = '@@equipment/GET_EQUIPMENT_PERFORMANCE_ASYNC_REPORT_LOADING',
  GET_EQUIPMENT_PERFORMANCE_ASYNC_REPORT_ERROR = '@@equipment/GET_EQUIPMENT_PERFORMANCE_ASYNC_REPORT_ERROR',
}

const setEquipmentFilter = (filter?: EquipmentApiFilter): string => {
  const queryParams = new URLSearchParams();

  if (filter && filter.limit) {
    queryParams.set('limit', filter.limit.toString());
  }

  if (
    filter &&
    filter.equipmentGroupIds &&
    filter.equipmentGroupIds.length > 0
  ) {
    queryParams.set('equipmentGroupIds', filter.equipmentGroupIds.join(','));
  }

  if (filter && filter.equipmentTypeIds && filter.equipmentTypeIds.length > 0) {
    queryParams.set('equipmentTypeIds', filter.equipmentTypeIds.join(','));
  }

  if (filter && filter.siteIds && filter.siteIds.length > 0) {
    queryParams.set('siteIds', filter.siteIds.join(','));
  }

  if (filter && filter.sensorTypes) {
    queryParams.set('sensorTypes', filter.sensorTypes.join(','));
  }

  queryParams.set('withPath', 'true');

  return queryParams.toString();
};

export const fetchEquipment = (
  filter?: EquipmentApiFilter
): Promise<Equipment[]> =>
  fetchApi({
    endpoint: `/api/v1/equipment?${setEquipmentFilter(filter)}`,
  });

export const getEquipment = (filter?: EquipmentApiFilter) => ({
  type: 'API_GET',
  path: `/api/v1/equipment?${formatAPIFilters<EquipmentApiFilter>(filter)}`,
  loading: Actions.GET_EQUIPMENT_LOADING,
  success: Actions.GET_EQUIPMENT_SUCCESS,
  error: Actions.GET_EQUIPMENT_ERROR,
});

export const clearEquipmentList = () => ({
  type: Actions.CLEAR_FILTERED_EQUIPMENT_LIST,
});

export const getEquipmentBySiteId = (siteId: number | string) => ({
  type: 'API_GET',
  path: `/api/v1/equipment/site/${siteId}`,
  loading: {
    type: Actions.GET_EQUIPMENT_BY_SITE_ID_LOADING,
    siteId,
  },
  success: {
    type: Actions.GET_EQUIPMENT_BY_SITE_ID_SUCCESS,
    siteId,
  },
  error: {
    type: Actions.GET_EQUIPMENT_BY_SITE_ID_ERROR,
    siteId,
  },
});

export const fetchEquipmentById = (equipmentId: number): Promise<Equipment> =>
  fetchApi({
    endpoint: `/api/v1/equipment/${equipmentId}/?withPath=true`,
  });

export const getEquipmentById = (equipmentId: number) => ({
  type: 'API_GET',
  path: `/api/v1/equipment/${equipmentId}/?withPath=true`,
  loading: {
    type: Actions.GET_EQUIPMENT_BY_ID_LOADING,
    equipmentId,
  },
  success: {
    type: Actions.GET_EQUIPMENT_BY_ID_SUCCESS,
    equipmentId,
  },
  error: {
    type: Actions.GET_EQUIPMENT_BY_ID_ERROR,
    equipmentId,
  },
});

export const getEquipmentsBySpaceId = (spaceId: number | string) => ({
  type: 'API_GET',
  path: `/api/v1/equipment/space/${spaceId}`,
  loading: { type: Actions.GET_EQUIPMENT_BY_SPACE_ID_LOADING, spaceId },
  success: { type: Actions.GET_EQUIPMENT_BY_SPACE_ID_SUCCESS, spaceId },
  error: { type: Actions.GET_EQUIPMENT_BY_SPACE_ID_ERROR, spaceId },
});

export const fetchEquipmentBySiteId = (siteId: number) =>
  fetchApi({
    endpoint: `/api/v1/equipment/site/${siteId.toString()}/`,
  });

export const fetchEquipmentBySiteIdAndSensorType = (
  siteId: number,
  sensorType: SensorType
): Promise<Equipment[]> =>
  fetchApi({
    endpoint: `/api/v1/equipment/site/${siteId.toString()}/sensors?sensorTypes=${sensorType}`,
  });

export const fetchEquipmentBySpaceId = (
  spaceId: number
): Promise<Equipment[]> =>
  fetchApi({
    endpoint: `/api/v1/equipment/space/${spaceId}`,
  });

export const getEquipmentCountPerGroupId = () => ({
  type: 'API_GET',
  path: '/api/v1/equipment/stats/equipmentGroupId',
  loading: Actions.GET_ALL_EQUIPMENT_COUNT_PER_GROUP_ID_LOADING,
  success: Actions.GET_ALL_EQUIPMENT_COUNT_PER_GROUP_ID_SUCCESS,
  error: Actions.GET_ALL_EQUIPMENT_COUNT_PER_GROUP_ID_ERROR,
});

export const getHvacControlGroupCount = () => ({
  type: 'API_GET',
  path: '/api/v1/equipment/stats/controlledEquipmentGroupId',
  loading: Actions.GET_HVAC_CONTROL_EQUIPMENT_COUNT_LOADING,
  success: Actions.GET_HVAC_CONTROL_EQUIPMENT_COUNT_SUCCESS,
  error: Actions.GET_HVAC_CONTROL_EQUIPMENT_COUNT_ERROR,
});

export const getEquipmentPerformanceByGroup = (
  orgId: number,
  equipmentGroupId: number,
  startDate: Date,
  endDate: Date
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/report/equipment_compare/${orgId}/${formatDateForNotificationsAPI(
    startDate
  )}/${formatDateForNotificationsAPI(
    endDate
  )}?type_group_id=${equipmentGroupId}`,
  loading: {
    type: Actions.GET_EQUIPMENT_PERFORMANCE_BY_GROUP_LOADING,
    equipmentGroupId,
  },
  success: {
    type: Actions.GET_EQUIPMENT_PERFORMANCE_BY_GROUP_SUCCESS,
    equipmentGroupId,
  },
  error: {
    type: Actions.GET_EQUIPMENT_PERFORMANCE_BY_GROUP_ERROR,
    equipmentGroupId,
  },
});

export const getEquipmentPerformanceByGroupAsyncId = (
  orgId: number,
  equipmentGroupId: number,
  startDate: Date,
  endDate: Date,
  siteIds: string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/async_report/equipment_compare_async/${orgId}/${formatDateForNotificationsAPI(
    startDate
  )}/${formatDateForNotificationsAPI(
    endDate
  )}?type_group_id=${equipmentGroupId}&site_id=${siteIds}`,
  loading: {
    type: Actions.GET_EQUIPMENT_PERFORMANCE_ASYNC_REPORT_ID_LOADING,
    equipmentGroupId,
  },
  success: {
    type: Actions.GET_EQUIPMENT_PERFORMANCE_ASYNC_REPORT_ID_SUCCESS,
    equipmentGroupId,
  },
  error: {
    type: Actions.GET_EQUIPMENT_PERFORMANCE_ASYNC_REPORT_ID_ERROR,
    equipmentGroupId,
  },
});

export const getEquipmentPerformanceByGroupAsync = (
  orgId: number,
  equipmentGroupId: number,
  reportId: string
) => ({
  type: 'API_GET',
  service: Service.analytics,
  path: `/report/equipment_compare_async/${orgId}/${reportId}?type_group_id=${equipmentGroupId}`,
  loading: {
    type: Actions.GET_EQUIPMENT_PERFORMANCE_ASYNC_REPORT_LOADING,
    equipmentGroupId,
  },
  success: {
    type: Actions.GET_EQUIPMENT_PERFORMANCE_ASYNC_REPORT_SUCCESS,
    equipmentGroupId,
  },
  error: {
    type: Actions.GET_EQUIPMENT_PERFORMANCE_ASYNC_REPORT_ERROR,
    equipmentGroupId,
  },
});

export const clearEquipmentPerformance = () => ({
  type: Actions.CLEAR_EQUIPMENT_PERFORMANCE,
});

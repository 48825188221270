const orgNameToId = {
  EB_PROD: 275,
  EB_STAGING: 5786,
  CKA: 50780,
  BIO_REFERENCE: 81521,
  DUALE: 53268,
  HOLIDAY: 1257,
  MTG: 36052,
  MY_PLACE: 48779,
  LAB_CORP: 38782,
  STORE_FRIENDLY: [15778, 82212],
  QFRM: 214332,
  YELLOW_CAB: 234840,
  NEW_FRONTIER_TECH: 51439,
  LVMH: [
    294269, 527738, 269691, 511159, 297253, 506001, 317465, 438694, 322886,
    537403, 526153, 129738, 252055, 1407402, 536858,
  ],
  WKS: 1476787,
  HAMRA: 1404907,
};

export const {
  LAB_CORP,
  HOLIDAY,
  STORE_FRIENDLY,
  EB_PROD,
  EB_STAGING,
  LVMH,
  WKS,
  HAMRA,
} = orgNameToId;

export const PERFORMANCE_PAGE_TAB_BLACKLIST = [HOLIDAY];
export const STORAGE_REPORT_WHITELIST = [...STORE_FRIENDLY];
export const COMMENTS_FEATURE_BLACKLIST = [];
export const TEMPERATURE_REVIEW_BLACKLIST = [];
export const INSIGHT_PAGE_SUMMARY_TAB_WHITELIST = [
  EB_PROD,
  EB_STAGING,
  ...LVMH,
];
/*CTRL-3718 - Delete after the POC for EB Platform Performance Review Dashboard */
export const INSIGHT_PAGE_SAVINGS_TAB_WHITELIST = [WKS, EB_STAGING, HAMRA];

import {
  ExtraShortSkeletonCell,
  LongSkeletonCell,
  ShortMediumSkeletonCell,
} from '@energybox/react-ui-library/dist/components';
import {
  Columns,
  TableGroupHeader,
} from '@energybox/react-ui-library/dist/components/Table';
import {
  FilterTimePeriod,
  Incident,
  Notification,
  OpacityIndex,
  SentinelPriorityLevel,
  SitesById,
  SortDirection,
  Status,
} from '@energybox/react-ui-library/dist/types';
import {
  filterResolvedIncidentsOutsideOfTimeRange,
  genericTableSort,
  global,
  SORT_IGNORED_VALUES,
} from '@energybox/react-ui-library/dist/utils';
import isWithinInterval from 'date-fns/isWithinInterval';
import mergeDeepRight from 'ramda/src/mergeDeepRight';
import pathOr from 'ramda/src/pathOr';

import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ShortenedSpan from '../../../../components/ShortenedSpan';
import SeeAllPage from '../../../../components/views/SeeAllPage';
import useFilteredNotifications from '../../../../hooks/useFilteredNotifications';
import { useSearchFilter, useTimeFilter } from '../../../../hooks/useFilters';
import { useGetIncidents } from '../../../../hooks/useIncidents';
import { useGetAllSites } from '../../../../hooks/useSites';
import * as Routes from '../../../../routes';
import SiteGroupFilter from '../../../../components/Filters/SiteGroupFilter';
import styles from './SitesWithMostIncidentsPage.module.css';
import { filterBySiteGroups } from '../../../../utils/siteGroups/siteGroups';
import useSiteGroupsFilter from '../../../../hooks/useSiteGroupsFilter';
import useSiteFilter from '../../../../hooks/useSiteFilter';
import SiteFilter from '../../../../components/Filters/SiteFilter';
import usePaginationFilter from '../../../../hooks/usePaginationFilter';
import NothingToReportOverlay from '../../../../components/NothingToReportOverlay';

type IncidentsBySiteId = {
  [siteId: string]: {
    incidents: Incident[];
  };
};

type NotificationsBySiteId = {
  [siteId: string]: {
    notifications: Notification[];
  };
};

type ProcessedData = {
  siteId: number;
  siteTitle: string;
  totalIncidents: number;
  newIncidents: number;
  existingIncidents: number;
  resolvedIncidents: number;
  lowPriorityNotifications: number;
  mediumPriorityNotifications: number;
  highPriorityNotifications: number;
  criticalPriorityNotifications: number;
};

const SitesWithMostIncidentsPage = () => {
  ///*** Hooks ***///
  const location = useLocation();
  const { timePeriod, setTimeFilter } = useTimeFilter();
  const sitesById = useGetAllSites();
  const {
    selectedSiteGroups,
    selectedSitesBySiteGroups,
    siteGroupWithoutSites,
  } = useSiteGroupsFilter();
  const { selectedSiteFilters } = useSiteFilter();
  const { isLoading: isIncidentsLoading, data: incidents = [] } =
    useGetIncidents({
      from: timePeriod.fromDate.toISOString(),
      to: timePeriod.toDate.toISOString(),
    });

  const { data: notifications, isLoading: isNotificationsLoading } =
    useFilteredNotifications(
      {
        fromDate: timePeriod.fromDate,
        toDate: timePeriod.toDate,
      },
      true
    );

  const filteredIncidents = useMemo(() => {
    return filterResolvedIncidentsOutsideOfTimeRange(
      incidents,
      timePeriod.fromDate,
      timePeriod.toDate
    );
  }, [incidents, timePeriod]);

  const isLoading = isIncidentsLoading || isNotificationsLoading;

  const processedData = useMemo(() => {
    return processData(filteredIncidents, notifications, sitesById, timePeriod);
  }, [incidents, notifications, sitesById, timePeriod]);

  const filterBySites = (data) => {
    return data.filter((sites) =>
      selectedSiteFilters.includes(Number(sites.siteId))
    );
  };

  /** Search Filter */
  const {
    query,
    setQuery,
    filteredList: filterSearchList,
  } = useSearchFilter(processedData, [['siteTitle']]);

  let filteredData = filterSearchList;

  if (selectedSiteGroups && selectedSiteGroups?.length) {
    filteredData = filterBySiteGroups(filteredData, selectedSitesBySiteGroups);
  }

  if (selectedSiteFilters && selectedSiteFilters?.length) {
    filteredData = filterBySites(filteredData);
  }

  const filteredrIncidentsByFilteredData = filteredIncidents.filter((data) =>
    selectedSiteFilters.includes(Number(data.siteId))
  );

  const isThereData = filteredData && filteredData.length > 0;
  const numberOfSites = filteredData.length;
  const totalIncidentCount =
    siteGroupWithoutSites || numberOfSites === 0
      ? 0
      : filteredrIncidentsByFilteredData.length || filteredIncidents.length;
  const loadAlternativeContent =
    !isLoading &&
    !isThereData &&
    !selectedSiteFilters?.length &&
    !selectedSiteGroups?.length;

  const { currentPage, rowLimit, setPagination } = usePaginationFilter(
    processedData?.length
  );

  return (
    <SeeAllPage
      title="Sites with Most Incidents"
      headerName="Sites with Most Incidents"
      backRoute={`${Routes.NOTIFICATION_OVERVIEW}${location.search}`}
      alternativeContent={
        loadAlternativeContent ? (
          <NothingToReportOverlay subtitle="" />
        ) : undefined
      }
      searchProps={{
        query,
        onChange: setQuery,
        error: filteredData.length === 0,
      }}
      summaryStatistics={[
        {
          value: isLoading ? global.NOT_AVAILABLE : totalIncidentCount,
          title: 'Number of Incidents',
          bold: true,
        },
      ]}
      displayCountText="sites"
      tableProps={{
        groupHeaders,
        columns,
        data: filteredData,
        dataIsLoading: isLoading,
        listView: true,
        rowLimitFromPaginationHook: rowLimit,
        currentPageFromPaginationHook: currentPage,
        setPagination: setPagination,
      }}
      filters={[
        <SiteFilter />,
        <SiteGroupFilter dropdownClassName={styles.siteGroupDropdown} />,
      ]}
    ></SeeAllPage>
  );
};

///*** Table Column Data ***///
const groupHeaders: TableGroupHeader[] = [
  {
    header: '',
    colSpan: 5,
  },
  {
    header: 'Notifications Sent',
    colSpan: 4,
  },
];

const columns: Columns<ProcessedData>[] = [
  {
    header: 'Site',
    width: '20%',
    cellContent: ({ siteTitle }) => (
      <div>
        <ShortenedSpan
          content={siteTitle}
          maxStringLength={40}
          arrowDirection={'bottom'}
        />
      </div>
    ),
    skeletonCellContent: (rowIndex: OpacityIndex) => (
      <LongSkeletonCell opacityIndex={rowIndex} />
    ),
    comparator: (
      a: ProcessedData,
      b: ProcessedData,
      sortDirection: SortDirection
    ) => {
      return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
        'siteTitle',
      ]);
    },
  },
  {
    width: '13%',
    header: 'Total Incidents',
    align: 'right',
    rightAlignContent: true,
    isDefaultSort: true,
    defaultSortDirection: SortDirection.DESC,
    cellContent: ({ siteId, totalIncidents }) => (
      <Link to={`/sites/${siteId}/incidents`}>{totalIncidents}</Link>
    ),
    skeletonCellContent: (rowIndex: OpacityIndex) => (
      <ShortMediumSkeletonCell opacityIndex={rowIndex} />
    ),
    comparator: (
      a: ProcessedData,
      b: ProcessedData,
      sortDirection: SortDirection
    ) => {
      return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
        'totalIncidents',
      ]);
    },
  },
  {
    header: 'New Incidents',
    width: '13%',
    align: 'right',
    rightAlignContent: true,
    defaultSortDirection: SortDirection.DESC,
    cellContent: ({ newIncidents }) => <div>{newIncidents}</div>,
    skeletonCellContent: (rowIndex: OpacityIndex) => (
      <ShortMediumSkeletonCell />
    ),
    comparator: (
      a: ProcessedData,
      b: ProcessedData,
      sortDirection: SortDirection
    ) => {
      return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
        'newIncidents',
      ]);
    },
  },
  {
    header: 'Existing Incidents',
    width: '13%',
    align: 'right',
    rightAlignContent: true,
    defaultSortDirection: SortDirection.DESC,
    cellContent: ({ existingIncidents }) => <div>{existingIncidents}</div>,
    skeletonCellContent: (rowIndex: OpacityIndex) => (
      <ShortMediumSkeletonCell opacityIndex={rowIndex} />
    ),
    comparator: (
      a: ProcessedData,
      b: ProcessedData,
      sortDirection: SortDirection
    ) => {
      return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
        'existingIncidents',
      ]);
    },
  },
  {
    header: 'Resolved Incidents',
    width: '13%',
    align: 'right',
    rightAlignContent: true,
    defaultSortDirection: SortDirection.DESC,
    cellContent: ({ resolvedIncidents }) => <div>{resolvedIncidents}</div>,
    skeletonCellContent: (rowIndex: OpacityIndex) => (
      <ShortMediumSkeletonCell opacityIndex={rowIndex} />
    ),
    comparator: (
      a: ProcessedData,
      b: ProcessedData,
      sortDirection: SortDirection
    ) => {
      return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
        'resolvedIncidents',
      ]);
    },
  },
  {
    header: 'Low',
    width: '7%',
    align: 'right',
    rightAlignContent: true,
    defaultSortDirection: SortDirection.DESC,
    cellContent: ({ lowPriorityNotifications }) => (
      <div>{lowPriorityNotifications}</div>
    ),
    skeletonCellContent: (rowIndex: OpacityIndex) => (
      <ExtraShortSkeletonCell opacityIndex={rowIndex} />
    ),
    comparator: (
      a: ProcessedData,
      b: ProcessedData,
      sortDirection: SortDirection
    ) => {
      return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
        'lowPriorityNotifications',
      ]);
    },
  },
  {
    header: 'Medium',
    width: '7%',
    align: 'right',
    rightAlignContent: true,
    defaultSortDirection: SortDirection.DESC,
    cellContent: ({ mediumPriorityNotifications }) => (
      <div>{mediumPriorityNotifications}</div>
    ),
    skeletonCellContent: (rowIndex: OpacityIndex) => (
      <ExtraShortSkeletonCell opacityIndex={rowIndex} />
    ),
    comparator: (
      a: ProcessedData,
      b: ProcessedData,
      sortDirection: SortDirection
    ) => {
      return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
        'mediumPriorityNotifications',
      ]);
    },
  },
  {
    header: 'High',
    width: '7%',
    align: 'right',
    rightAlignContent: true,
    defaultSortDirection: SortDirection.DESC,
    cellContent: ({ highPriorityNotifications }) => (
      <div>{highPriorityNotifications}</div>
    ),
    skeletonCellContent: (rowIndex: OpacityIndex) => (
      <ExtraShortSkeletonCell opacityIndex={rowIndex} />
    ),
    comparator: (
      a: ProcessedData,
      b: ProcessedData,
      sortDirection: SortDirection
    ) => {
      return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
        'highPriorityNotifications',
      ]);
    },
  },
  {
    header: 'Critical',
    width: '7%',
    align: 'right',
    rightAlignContent: true,
    defaultSortDirection: SortDirection.DESC,
    cellContent: ({ criticalPriorityNotifications }) => (
      <div>{criticalPriorityNotifications}</div>
    ),
    skeletonCellContent: (rowIndex: OpacityIndex) => (
      <ExtraShortSkeletonCell opacityIndex={rowIndex} />
    ),
    comparator: (
      a: ProcessedData,
      b: ProcessedData,
      sortDirection: SortDirection
    ) => {
      return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
        'criticalPriorityNotifications',
      ]);
    },
  },
];
///*** End Table Column Data ***///

///*** Local Functions ***///
const processData = (
  incidents: Incident[],
  notifications: Notification[],
  sitesById: SitesById,
  timePeriod: FilterTimePeriod
) => {
  if (incidents.length === 0 || notifications.length === 0) {
    return [];
  }

  const incidentsBySiteId = aggregateIncidentsBySiteId(incidents);
  const notificationsBySiteId = aggregateNotificationsBySiteId(notifications);

  const combinedDataBySiteId = mergeDeepRight(
    incidentsBySiteId,
    notificationsBySiteId
  );

  const processedData = Object.keys(combinedDataBySiteId).map((siteId) => {
    const incidentsList = pathOr(
      [],
      [siteId, 'incidents'],
      combinedDataBySiteId
    );
    const notificationsList = pathOr(
      [],
      [siteId, 'notifications'],
      combinedDataBySiteId
    );

    const totalIncidents = incidentsList.length;
    const newIncidents = incidentsList.filter(
      (i: Incident) => i.status === Status.NEW
    ).length;
    const existingIncidents = incidentsList.filter(
      (i: Incident) => i.status === Status.EXISTING
    ).length;
    const resolvedIncidents = incidentsList.filter(
      (i: Incident) => i.resolved === true
    ).length;
    const notificationsByPriority = determineNotificationsByPriority(
      notificationsList,
      timePeriod
    );

    return {
      siteId,
      siteTitle: pathOr(global.NOT_AVAILABLE, [siteId, 'title'], sitesById),
      totalIncidents,
      newIncidents,
      existingIncidents,
      resolvedIncidents,
      lowPriorityNotifications:
        notificationsByPriority.low || global.NOT_AVAILABLE,
      mediumPriorityNotifications:
        notificationsByPriority.medium || global.NOT_AVAILABLE,
      highPriorityNotifications:
        notificationsByPriority.high || global.NOT_AVAILABLE,
      criticalPriorityNotifications:
        notificationsByPriority.critical || global.NOT_AVAILABLE,
    };
  });

  return processedData;
};

const determineNotificationsByPriority = (
  notifications: Notification[],
  timePeriod: FilterTimePeriod
) => {
  let low = 0;
  let medium = 0;
  let high = 0;
  let critical = 0;

  notifications.forEach((n) => {
    n.handlingLogs.forEach((log) => {
      if (
        !isWithinInterval(new Date(log.at), {
          start: timePeriod.fromDate,
          end: timePeriod.toDate,
        })
      ) {
        return;
      }

      switch (log.priorityLevel) {
        case SentinelPriorityLevel.NORMAL:
          low += 1;
          break;
        case SentinelPriorityLevel.MEDIUM:
          medium += 1;
          break;
        case SentinelPriorityLevel.HIGH:
          high += 1;
          break;
        case SentinelPriorityLevel.HIGHEST:
          critical += 1;
          break;
        default:
          break;
      }
    });
  });

  return {
    low,
    medium,
    high,
    critical,
  };
};

const aggregateNotificationsBySiteId = (
  notifications: Notification[]
): NotificationsBySiteId => {
  const notificationsBySiteId = {};

  notifications.forEach((n) => {
    const siteId = n.siteId;
    if (!siteId) return;

    if (!notificationsBySiteId[siteId]) {
      notificationsBySiteId[siteId] = {
        notifications: [n],
      };
    } else {
      notificationsBySiteId[siteId]['notifications'] = [
        ...notificationsBySiteId[siteId]['notifications'],
        n,
      ];
    }
  });

  return notificationsBySiteId;
};

const aggregateIncidentsBySiteId = (
  incidents: Incident[]
): IncidentsBySiteId => {
  const incidentsBySiteId = {};

  incidents.forEach((i) => {
    const siteId = i.siteId;

    if (!incidentsBySiteId[siteId]) {
      incidentsBySiteId[siteId] = {
        incidents: [i],
      };
    } else {
      incidentsBySiteId[siteId]['incidents'] = [
        ...incidentsBySiteId[siteId]['incidents'],
        i,
      ];
    }
  });

  return incidentsBySiteId;
};
///*** End Local Functions ***///

export default SitesWithMostIncidentsPage;

import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useCurrentUser from '../../../hooks/useCurrentUser';
import styles from './EquipmentPerformance.module.css';
import BaseFilterBar from '../../../components/Filters/BaseFilterBar';
import {
  KWH_UNIT,
  SORT_IGNORED_VALUES,
  classNames,
  formatNumberString,
  genericTableSort,
  getDateFiltersWithoutTodayOrYesterday,
  isDefined,
  shortenString,
} from '@energybox/react-ui-library/dist/utils';
import {
  ViewportTypes,
  useViewportType,
} from '@energybox/react-ui-library/dist/hooks';
import useAppLocale from '../../../hooks/useAppLocale';
import {
  PageContentHeader,
  PageContentWrapper,
} from '../../../components/Page';
import {
  DateFilter,
  GenericMultipleFilter,
  Loader,
  MediaElement,
  MediumLongSkeletonCell,
  ProgressBar,
  SearchBox,
  ShortMediumSkeletonCell,
  ShortSkeletonCell,
  SkeletonThumbnail,
  Table,
  Tooltip,
} from '@energybox/react-ui-library/dist/components';
import FiltersContainer from '../../../components/Filters/FiltersContainer/FiltersContainer';
import SiteGroupFilter from '../../../components/Filters/SiteGroupFilter';
import SiteFilter from '../../../components/Filters/SiteFilter';
import EquipmentMenuFilter from '../../../components/Filters/FilterMenus/EquipmentMenuFilter';
import VendorFilter from '../../../components/Filters/VendorFilter/VendorFilter';
import ReportGenerateDropDownButton from '../../../components/Button/ReportGenerateButton/ReportGenerateDropDownButton';
import { TableWrapper } from '../../../components/Tables';
import NothingToReportOverlay from '../../../components/NothingToReportOverlay';
import theme from '../../../styles/theme';
import useDynamicFilter from '../../../hooks/useDynamicFilter';
import {
  SetNotificationDateFilter,
  setNotificationDateFilter as setNotificationDateFilterAction,
} from '../../../actions/notificationFilter';
import {
  CurrentUser,
  EquipmentTypesById,
  FilterTimePeriod,
  OpacityIndex,
  SortDirection,
  TemperatureUnit,
} from '@energybox/react-ui-library/dist/types';
import { useSearchFilter, useTimeFilter } from '../../../hooks/useFilters';
import useSiteFilter from '../../../hooks/useSiteFilter';
import {
  useEquipmentTypes,
  useEquipmentTypesById,
} from '../../../hooks/useEquipmentTypes';
import { Columns } from '@energybox/react-ui-library/dist/components/Table';
import {
  celsiusStdDevToFahrenheit,
  createTemperatureString,
  formatValueAsTemp,
  getUserPreferenceTemperatureUnit,
} from '@energybox/react-ui-library/dist/utils/temperature';
import { ApplicationState } from '../../../reducers';
import { Link, useHistory } from 'react-router-dom';
import { global } from '@energybox/react-ui-library/dist/utils';
import useSiteGroupsFilter from '../../../hooks/useSiteGroupsFilter';
import {
  filterBySiteGroups,
  sortSiteGroupTitles,
} from '../../../utils/siteGroups/siteGroups';
import usePaginationFilter from '../../../hooks/usePaginationFilter';
import { Thermostat_complaince_Equipment } from '../../../reducers/analytics';
import { ORG_EQUIPMENT } from '../../../routes';
import { useToReport } from '../../../hooks/reportworker/useToReport';
import { ReportTemplate, ReportType } from '../../../types/reportworker';
import { displayCount } from '../../../components/Tables/TableWrapper/TableWrapper';
import {
  convertToHoursAndMinutes,
  formatDateToWords,
  formatNumber,
} from '../../../util';
import {
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip as RechartsTooltip,
} from 'recharts';
import { useEffectClearTodayDateFilterOnLoad } from './EquipmentPerformance';
import { useHvacControlsCompliance } from '../../../hooks/useHvacControlsComplaiance';
import { IoIosInformationCircleOutline } from 'react-icons/io';

type columnType = {
  data: Thermostat_complaince_Equipment;
  equipmentId: string;
};

enum ColumnHeaders {
  EQUIPMENT_NAME = 'Equipment',
  EQUIPMENT_TYPE = 'Type',
  SITE = 'Site',
  VENDOR_MODEL = 'Manufacturer/ Model',
  COMPLIANCE_RATE = 'Setpoint Compliance',
  TOTAL_ENERGY_CONSUMPTION = 'Energy Consumed (KWh)',
  AVERAGE_TEMPERATURE = 'Space Temperature',
  STANDARD_DEVIATION = 'Deviation from Setpoint',
  TRENDS = 'Compliance Trend',
  COOLING_RUNTIME = 'Daily Cooling Runtime (hrs.)',
  HEATING_RUNTIME = 'Daily Heating Runtime (hrs.)',
}

const columns: (
  isMobile,
  equipmentTypesById,
  currentUser
) => Columns<columnType>[] = (
  isMobile: boolean,
  equipmentTypesById: EquipmentTypesById,
  currentUser: CurrentUser
) => {
  const temperatureUnit =
    currentUser && getUserPreferenceTemperatureUnit(currentUser);
  return [
    {
      header: ColumnHeaders.EQUIPMENT_NAME,
      highlightIsActive: !isMobile,
      showSortIcons: !isMobile,
      width: isMobile ? '8rem' : undefined,
      cellContent: ({
        equipmentId,
        data: { equipment_title, equipment_type_id, site_id },
      }: columnType) => {
        const title = equipment_title
          ? shortenString(equipment_title, isMobile ? 30 : 35)
          : global.NOT_AVAILABLE;
        const description =
          isMobile && equipmentTypesById[equipment_type_id]?.title
            ? shortenString(equipmentTypesById[equipment_type_id].title, 30)
            : '';
        return (
          <MediaElement
            title={
              <Link
                to={`/sites/${site_id}/equipment/${equipmentId}`}
                className={isMobile ? styles.mobileEquipmentTitle : ''}
                target="_blank"
              >
                <span>{title}</span>
              </Link>
            }
            description={description}
            className={
              isMobile && title.length >= 20 && description.length >= 20
                ? styles.extraSmallEquipmentFont
                : ''
            }
          />
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <MediaElement
          icon={
            !isMobile && (
              <SkeletonThumbnail
                width={24}
                height={24}
                opacityIndex={rowIndex}
              />
            )
          }
          title={<MediumLongSkeletonCell opacityIndex={rowIndex} />}
        />
      ),
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'data',
          'equipment_title',
        ]);
      },
    },
    {
      header: ColumnHeaders.EQUIPMENT_TYPE,
      highlightIsActive: !isMobile,
      showSortIcons: !isMobile,
      cellContent: ({ data: { equipment_type_id } }: columnType) => {
        if (equipmentTypesById[equipment_type_id] === undefined)
          return global.NOT_AVAILABLE;
        return equipmentTypesById[equipment_type_id].title;
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <MediaElement
          icon={<SkeletonThumbnail width={24} height={24} />}
          title={<MediumLongSkeletonCell opacityIndex={rowIndex} />}
        />
      ),
      comparator: (a, b, sortDirection) => {
        return genericTableSort(
          a,
          b,
          sortDirection,
          SORT_IGNORED_VALUES,
          ({ data: { equipment_type_id } }: columnType) => {
            if (equipmentTypesById[equipment_type_id] === undefined)
              return global.NOT_AVAILABLE;
            return equipmentTypesById[equipment_type_id].title;
          }
        );
      },
    },
    {
      header: ColumnHeaders.SITE,
      highlightIsActive: !isMobile,
      showSortIcons: !isMobile,
      cellContent: ({ data: { site_title } }: columnType) => (
        <span>{site_title}</span>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortMediumSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'data',
          'site_title',
        ]);
      },
    },
    {
      header: ColumnHeaders.VENDOR_MODEL,
      highlightIsActive: !isMobile,
      showSortIcons: !isMobile,
      cellContent: ({ data: { vendor, model } }: columnType) => (
        <span>
          {(!vendor ||
            vendor === 'Unnamed' ||
            vendor === global.NOT_AVAILABLE) &&
          !model
            ? global.NOT_AVAILABLE
            : `${vendor} / ${model}`}
        </span>
      ),
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortMediumSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a, b, sortDirection) => {
        return genericTableSort(
          a,
          b,
          sortDirection,
          SORT_IGNORED_VALUES,
          ['data', 'vendor'],
          ['data', 'model']
        );
      },
    },
    {
      header: ColumnHeaders.COMPLIANCE_RATE,
      highlightIsActive: !isMobile,
      showSortIcons: !isMobile,
      cellContent: ({
        data: { temperature_comp, compliance_below_threshold },
      }: columnType) => {
        const compliancePercentage =
          temperature_comp?.compliance_rate.percent * 100;
        return (
          <span
            className={compliance_below_threshold ? styles.highlightValue : ''}
          >
            {compliancePercentage === undefined ||
            isNaN(compliancePercentage) ||
            compliancePercentage === null
              ? global.NOT_AVAILABLE
              : formatNumberString(compliancePercentage, {
                  numDecimals: 1,
                  unit: '%',
                  spaceBetweenValueAndUnit: false,
                })}
          </span>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortMediumSkeletonCell opacityIndex={rowIndex} />
      ),
      defaultSortDirection: SortDirection.ASC,
      isDefaultSort: true,
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'data',
          'temperature_comp',
          'compliance_rate',
          'percent',
        ]);
      },
    },
    {
      header: ColumnHeaders.TRENDS,
      highlightIsActive: !isMobile,
      showSortIcons: !isMobile,
      align: 'center',
      width: '10%',
      cellStyle: { height: '50px' },
      cellContent: ({ data: { compliance_trend } }: columnType) => {
        const chartData = Object.keys(compliance_trend).map((date) => {
          const value = compliance_trend[date];
          const roundedValue = value !== null ? value * 100 : null;
          return {
            date,
            value: roundedValue,
          };
        });
        const CustomTooltip = ({ active, payload, label }) => {
          if (active && payload && payload.length) {
            const { date, value } = payload[0].payload;
            return (
              <div className={styles.tooltipContent}>
                <p className={styles.date}>{formatDateToWords(date)}</p>
                <p className={styles.percentage}>{value.toFixed(1)}%</p>
              </div>
            );
          }
          return null;
        };

        return (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart width={150} height={50} data={chartData}>
              <Line
                type="monotone"
                dataKey="value"
                stroke="#00a1af"
                strokeWidth={2.2}
                dot={{ fill: '#00a1af', strokeWidth: 0, r: 1.2 }}
                isAnimationActive={false}
              />
              <RechartsTooltip content={CustomTooltip} />
            </LineChart>
          </ResponsiveContainer>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => <ShortSkeletonCell />,
    },
    {
      header: ColumnHeaders.AVERAGE_TEMPERATURE,
      highlightIsActive: !isMobile,
      showSortIcons: !isMobile,
      align: 'right',
      rightAlignContent: true,
      cellContent: ({ data: { temperature_comp } }: columnType) => {
        const averageTemperature = temperature_comp?.avg_temp;
        return (
          <span>
            {!isDefined(averageTemperature) || isNaN(averageTemperature)
              ? global.NOT_AVAILABLE
              : createTemperatureString(averageTemperature, currentUser)}
          </span>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortMediumSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'data',
          'temperature_comp',
          'avg_temp',
        ]);
      },
    },
    {
      header: ColumnHeaders.STANDARD_DEVIATION,
      highlightIsActive: !isMobile,
      showSortIcons: !isMobile,
      align: 'right',
      rightAlignContent: true,
      cellContent: ({ data: { temperature_comp } }: columnType) => {
        const temperatureDeviation = temperature_comp?.avg_dev_from_setpoint;
        return (
          <span>
            {temperatureDeviation === undefined ||
            isNaN(temperatureDeviation) ||
            temperatureDeviation === null
              ? global.NOT_AVAILABLE
              : formatValueAsTemp(
                  formatNumber(
                    temperatureUnit === TemperatureUnit.C
                      ? temperatureDeviation
                      : celsiusStdDevToFahrenheit(temperatureDeviation),
                    1
                  ),
                  currentUser
                )}
          </span>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortMediumSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'data',
          'temperature_comp',
          'avg_dev_from_setpoint',
        ]);
      },
    },
    {
      header: ColumnHeaders.COOLING_RUNTIME,
      highlightIsActive: !isMobile,
      showSortIcons: !isMobile,
      align: 'right',
      rightAlignContent: true,
      cellContent: ({
        data: { temperature_comp, cooling_runtime_above_threshold },
      }: columnType) => {
        const cooling = temperature_comp?.cooling?.overall;
        const percentage = cooling?.percent * 100;
        const hours = convertToHoursAndMinutes(cooling?.valid_points);
        return temperature_comp !== null ? (
          <>
            <span>{hours}</span>
            <ProgressBar
              perceivedProgress={percentage}
              className={styles.progressBar}
              highlightValue={cooling_runtime_above_threshold ? true : false}
              tooltip={true}
            />
          </>
        ) : (
          <>{global.NOT_AVAILABLE}</>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'data',
          'temperature_comp',
          'cooling',
          'overall',
          'valid_points',
        ]);
      },
    },
    {
      header: ColumnHeaders.HEATING_RUNTIME,
      highlightIsActive: !isMobile,
      showSortIcons: !isMobile,
      align: 'right',
      rightAlignContent: true,
      cellContent: ({
        data: { temperature_comp, heating_runtime_above_threshold },
      }: columnType) => {
        const heating = temperature_comp?.heating?.overall;
        const percentage = heating?.percent * 100;
        const hours = convertToHoursAndMinutes(heating?.valid_points);
        return temperature_comp !== null ? (
          <>
            <span>{hours}</span>
            <ProgressBar
              perceivedProgress={percentage}
              className={styles.progressBar}
              highlightValue={heating_runtime_above_threshold ? true : false}
              tooltip={true}
            />
          </>
        ) : (
          <>{global.NOT_AVAILABLE}</>
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'data',
          'temperature_comp',
          'heating',
          'overall',
          'valid_points',
        ]);
      },
    },
    {
      header: ColumnHeaders.TOTAL_ENERGY_CONSUMPTION,
      highlightIsActive: !isMobile,
      showSortIcons: !isMobile,
      align: 'right',
      rightAlignContent: true,
      cellContent: ({ data: { temperature_comp } }: columnType) => {
        const energy = temperature_comp?.energy;
        return energy || energy === 0 ? (
          <span>
            {formatNumberString(energy, {
              numDecimals: 1,
              unit: KWH_UNIT,
            })}
          </span>
        ) : (
          global.NOT_AVAILABLE
        );
      },
      skeletonCellContent: (rowIndex: OpacityIndex) => (
        <ShortSkeletonCell opacityIndex={rowIndex} />
      ),
      comparator: (a, b, sortDirection) => {
        return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [
          'data',
          'temperature_comp',
          'energy',
        ]);
      },
    },
  ];
};

export enum GroupID {
  HVAC = Number(
    process.env.REACT_APP_SERVICE_ENV === 'production' ? 221 : 5708
  ),
}

const ControlledHvacPerformance = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useCurrentUser();
  const { timePeriod, setTimeFilter } = useTimeFilter();
  const { selectedSiteFilters } = useSiteFilter();

  const {
    isDataLoading: isLoading,
    performanceData,
    reportKeyLoading,
    complianceThreshold,
    runtimeThreshold,
    loadingSeconds,
  } = useHvacControlsCompliance();

  const { selectedFilters: selectedVendors, setFilter: setSelectedVendors } =
    useDynamicFilter<string>('vendor');

  const {
    selectedFilters: activeEquipmentFilters,
    setFilter: setEquipmentTypeFilter,
  } = useDynamicFilter<number>('eqType', (value) => parseInt(value));

  const setNotificationDateFilter: SetNotificationDateFilter = useCallback(
    (timeFilter: FilterTimePeriod) =>
      dispatch(setNotificationDateFilterAction(timeFilter)),
    [dispatch]
  );

  const { selectedSiteGroups, setSiteGroupsFilter, selectedSitesBySiteGroups } =
    useSiteGroupsFilter();

  const siteGroupsById = useSelector(
    ({ siteGroups }: ApplicationState) => siteGroups.siteGroupsById ?? {}
  );

  const siteGroupsTitles = sortSiteGroupTitles(siteGroupsById);

  const filteredDataByEquipmentId = performanceData?.filter(
    ([id, { equipment_type_id }]) => {
      if (activeEquipmentFilters.length === 0) {
        return true;
      } else {
        return activeEquipmentFilters.includes(equipment_type_id);
      }
    }
  );

  const availableVendors = useMemoAvailableVendors(performanceData);

  const filteredDataByVendors = filteredDataByEquipmentId?.filter(
    ([id, { vendor }]) => {
      if (selectedVendors.length === 0) {
        return true;
      } else if (vendor === null) {
        return false;
      } else {
        return selectedVendors.includes(vendor);
      }
    }
  );

  const filteredBySites = filteredDataByVendors?.filter(([id, { site_id }]) => {
    if (selectedSiteFilters.length === 0) {
      return true;
    } else {
      return selectedSiteFilters.find(
        (selectedSiteId) => selectedSiteId === site_id
      );
    }
  });

  const filteredData =
    selectedSiteGroups && selectedSiteGroups?.length
      ? filterBySiteGroups(filteredBySites, selectedSitesBySiteGroups)
      : filteredBySites;

  const equipmentTypes = useEquipmentTypes();
  const equipmentTypesById = useEquipmentTypesById();
  const {
    query,
    setQuery,
    filteredList: filterSearchList,
  } = useSearchFilter(
    filteredData,
    [
      ['1', 'site_title'],
      ['1', 'siteGroups'],
      ['1', 'equipment_title'],
      ['1', 'vendor'],
    ],
    [
      {
        reference: equipmentTypes,
        searchReferenceKey: ['title'],
        referenceKey: ['id'],
        key: ['equipment_type_id'],
      },
    ]
  );

  const { trigger } = useToReport();
  const downloadReport = (reportType: ReportType) => {
    trigger({
      reportType,
      timePeriod,
      reportTemplate: ReportTemplate.HVAC_CONTROLLED_PERFORMANCE,
      equipmentGroupId: GroupID.HVAC,
      filterSiteIds: selectedSiteFilters,
      filterEquipmentTypeId: activeEquipmentFilters,
      filterVendor: selectedVendors,
    });
  };

  const isMobile = useViewportType() === ViewportTypes.MOBILE;
  const locale = useAppLocale();

  const { currentPage, rowLimit, setPagination } = usePaginationFilter(
    performanceData?.length
  );

  const mobileFilters = [
    {
      title: 'Site',
      customFilter: () => <SiteFilter isInDropdown={true} />,
      selectedItems: selectedSiteFilters,
    },
    {
      title: 'Site Groups',
      setFilter: setSiteGroupsFilter,
      items: siteGroupsTitles,
      selectedItems: selectedSiteGroups,
    },
    {
      title: 'Equipment Type',
      setFilter: setEquipmentTypeFilter,
      items: Object.values(equipmentTypesById)
        .filter((type) => type.groupId === 5708)
        .map((type) => type.id),
      selectedItems: activeEquipmentFilters,
      transformItemName: (typeId) => {
        const type = equipmentTypesById[typeId];
        return type !== undefined ? type.title : '';
      },
    },
    {
      title: 'Vendor',
      setFilter: setSelectedVendors,
      items: availableVendors,
      selectedItems: selectedVendors,
    },
  ];

  useEffectClearTodayDateFilterOnLoad(timePeriod, setTimeFilter);

  const title = (
    <>
      Performance{' '}
      {
        <InfoTooltip
          compliance={complianceThreshold}
          runTime={runtimeThreshold}
        />
      }
    </>
  );

  const getTimeRemaining = () => {
    if (loadingSeconds === undefined || loadingSeconds === 0) {
      return '';
    } else {
      const secondsRemaining = 60 - loadingSeconds * 60;
      return `${secondsRemaining.toFixed()} secs remaining`;
    }
  };

  return (
    <div className={styles.root}>
      <BaseFilterBar
        className={isMobile ? styles.mobileTopBar : ''}
        navigateBack={() => history.push(ORG_EQUIPMENT)}
      >
        <div
          className={classNames(
            styles.barHeader,
            isMobile ? styles.mobileBarHeader : ''
          )}
        >
          HVAC (Controlled)
        </div>
      </BaseFilterBar>
      <PageContentWrapper hasPadding>
        <PageContentHeader header={title}>
          <SearchBox
            placeholder="Quick Search"
            onChange={setQuery}
            query={query}
            width={
              isMobile
                ? theme.size.table.searchBox.mobile
                : theme.size.table.searchBox.web
            }
            widthActive={
              isMobile
                ? theme.size.table.searchBox.mobile
                : theme.size.table.searchBox.web
            }
            error={performanceData?.length === 0}
          />
        </PageContentHeader>

        <FiltersContainer growFirst>
          {!isMobile && (
            <FiltersContainer>
              <SiteFilter />
              <SiteGroupFilter />
              <EquipmentMenuFilter
                label="Equipment Type"
                isOnlyHvacType={true}
              />
              <VendorFilter vendors={availableVendors} />
            </FiltersContainer>
          )}

          <DateFilter
            value={timePeriod}
            setFilter={setNotificationDateFilter}
            optionCreator={getDateFiltersWithoutTodayOrYesterday}
            customPickerVariant="date"
            locale={locale}
            alignItemsRight
          />
          {
            <ReportGenerateDropDownButton
              download={downloadReport}
              disabled={isLoading}
            />
          }

          {isMobile && (
            <GenericMultipleFilter
              className={styles.mobilePriorityAndStatusFilter}
              dropdownClassName={styles.mobileFilterDropdown}
              title="Filters"
              filters={mobileFilters}
              alignItemsRight
            />
          )}
        </FiltersContainer>

        <TableWrapper
          hideTopBar={filterSearchList?.length == 0}
          header={
            isLoading
              ? undefined
              : displayCount(performanceData?.length, 'Equipment')
          }
        >
          <Table
            headerClassName={isMobile ? styles.mobileHeader : ''}
            columns={columns(isMobile, equipmentTypesById, currentUser)}
            highlightScrollableColumns={!isMobile}
            noHeaderShadow={isMobile}
            data={
              filterSearchList?.length
                ? filterSearchList?.map((c) => ({
                    data: c[1],
                    equipmentId: c[0],
                  }))
                : []
            }
            dataIsLoading={isLoading || reportKeyLoading}
            rowHeight="4rem"
            listView
            rowLimitFromPaginationHook={rowLimit}
            currentPageFromPaginationHook={currentPage}
            setPagination={setPagination}
            headerWrap={true}
          />

          {(isLoading || reportKeyLoading) && (
            <div className={styles.loaderOverlay}>
              <div className={styles.loaderContainer}>
                <Loader size={64} />
                <h4 style={{ marginTop: 20, marginBottom: 12 }}>
                  Data is loading...
                </h4>
                <p>{getTimeRemaining()}</p>
              </div>
            </div>
          )}
        </TableWrapper>
        {filterSearchList?.length == 0 && !isLoading && (
          <NothingToReportOverlay />
        )}
      </PageContentWrapper>
    </div>
  );
};

export default ControlledHvacPerformance;

export const useMemoAvailableVendors = (data) => {
  return useMemo<string[]>(() => {
    const vendorSet = new Set<string>();
    data?.forEach(([id, { vendor }], index) => {
      if (vendor !== null && vendor !== '') {
        vendorSet.add(vendor);
      }
    });
    return Array.from(vendorSet).sort();
  }, [data]);
};

export const InfoTooltip = ({ compliance, runTime }) => (
  <div>
    <Tooltip
      arrowDirection={'closeBottom'}
      underline={false}
      tooltipTextClassName={styles.titleText}
      content={
        <div>
          <p>
            Setpoint Compliance considers deadband settings and a time allowance
            when changing setpoints.
          </p>
          <p>
            Runtime includes both Stage1 and Stage2 compressors where available.
          </p>
          <p>
            The calculation excludes nighttime setback (“Unoccupied” Hours) or
            when the HVAC is switched off.
          </p>
          <p>All values are averaged over the selected time period.</p>
          <p>
            {`This page highlights HVAC Equipment with Setpoint Compliance <${compliance}% and Daily Compressor Runtime >${runTime}%`}
          </p>
        </div>
      }
    >
      <IoIosInformationCircleOutline size={20} />
    </Tooltip>
  </div>
);

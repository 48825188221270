import { Service } from '../config';
import { WorkingMode } from '@energybox/react-ui-library/dist/types';
import { formatDateForTimeSeriesApi } from '@energybox/react-ui-library/dist/utils';
import { PrimedControlModeUpdate } from '../reducers/types/controlsReducer';

const apiBase = '/api/v1';

export enum Actions {
  GET_TEMPERATURE_CONTROLS_BY_SITE_ID_SUCCESS = '@controls/GET_TEMPERATURE_CONTROLS_BY_SITE_ID_SUCCESS',
  GET_TEMPERATURE_CONTROLS_BY_SITE_ID_ERROR = '@controls/GET_TEMPERATURE_CONTROLS_BY_SITE_ID_ERROR',
  GET_TEMPERATURE_CONTROLS_BY_SITE_ID_LOADING = '@controls/GET_TEMPERATURE_CONTROLS_BY_SITE_ID_LOADING',

  GET_CONTROLS_OVERVIEW_BY_SITE_ID_SUCCESS = '@controls/GET_CONTROLS_OVERVIEW_BY_SITE_ID_SUCCESS',
  GET_CONTROLS_OVERVIEW_BY_SITE_ID_ERROR = '@controls/GET_CONTROLS_OVERVIEW_BY_SITE_ID_ERROR',
  GET_CONTROLS_OVERVIEW_BY_SITE_ID_LOADING = '@controls/GET_CONTROLS_OVERVIEW_BY_SITE_ID_LOADING',

  GET_SCHEDULER_BY_EQUIPMENT_ID_SUCCESS = '@controls/GET_SCHEDULER_BY_EQUIPMENT_ID_SUCCESS',
  GET_SCHEDULER_BY_EQUIPMENT_ID_ERROR = '@controls/GET_SCHEDULER_BY_EQUIPMENT_ID_ERROR',
  GET_SCHEDULER_BY_EQUIPMENT_ID_LOADING = '@controls/GET_SCHEDULER_BY_EQUIPMENT_ID_LOADING',

  GET_SCHEDULERS_BY_SITE_ID_SUCCESS = '@controls/GET_SCHEDULERS_BY_SITE_ID_SUCCESS',
  GET_SCHEDULERS_BY_SITE_ID_ERROR = '@controls/GET_SCHEDULERS_BY_SITE_ID_ERROR',
  GET_SCHEDULERS_BY_SITE_ID_LOADING = '@controls/GET_SCHEDULERS_BY_SITE_ID_LOADING',

  TOGGLE_UPDATE_CONTROL_WORKING_MODE_MODAL = '@controls/TOGGLE_UPDATE_CONTROL_WORKING_MODE_MODAL',

  PATCH_SCHEDULER_WORKING_MODE_LOADING = '@schedulers/PATCH_SCHEDULER_WORKING_MODE_LOADING',
  PATCH_SCHEDULER_WORKING_MODE_SUCCESS = '@schedulers/PATCH_SCHEDULER_WORKING_MODE_SUCCESS',
  PATCH_SCHEDULER_WORKING_MODE_ERROR = '@schedulers/PATCH_SCHEDULER_WORKING_MODE_ERROR',

  PATCH_TEMPERATURE_CONTROL_WORKING_MODE_LOADING = '@schedulers/PATCH_TEMPERATURE_CONTROL_WORKING_MODE_LOADING',
  PATCH_TEMPERATURE_CONTROL_WORKING_MODE_SUCCESS = '@schedulers/PATCH_TEMPERATURE_CONTROL_WORKING_MODE_SUCCESS',
  PATCH_TEMPERATURE_CONTROL_WORKING_MODE_ERROR = '@schedulers/PATCH_TEMPERATURE_CONTROL_WORKING_MODE_ERROR',

  PATCH_HVAC_CONTROL_WORKING_MODE_LOADING = '@hvacControl/PATCH_HVAC_CONTROL_WORKING_MODE_LOADING',
  PATCH_HVAC_CONTROL_WORKING_MODE_SUCCESS = '@hvacControl/PATCH_HVAC_CONTROL_WORKING_MODE_SUCCESS',
  PATCH_HVAC_CONTROL_WORKING_MODE_ERROR = '@hvacControl/PATCH_HVAC_CONTROLS_WORKING_MODE_ERROR',

  GET_CONTROL_BOARD_ACTUATORS_FOR_SITE_ID_SUCCESS = '@controls/GET_CONTROL_BOARD_ACTUATOR_FOR_SITE_ID_SUCCESS',
  GET_CONTROL_BOARD_ACTUATORS_FOR_SITE_ID_LOADING = '@controls/GET_CONTROL_BOARD_ACTUATOR_FOR_SITE_ID_LOADING',
  GET_CONTROL_BOARD_ACTUATORS_FOR_SITE_ID_ERROR = '@controls/GET_CONTROL_BOARD_ACTUATOR_FOR_SITE_ID_ERROR',

  GET_ACTUATORS_BY_EQUIPMENT_ID_SUCCESS = '@controls/GET_ACTUATORS_BY_EQUIPMENT_ID_SUCCESS',
  GET_ACTUATORS_BY_EQUIPMENT_ID_ERROR = '@controls/GET_ACTUATORS_BY_EQUIPMENT_ID_ERROR',
  GET_ACTUATORS_BY_EQUIPMENT_ID_LOADING = '@controls/GET_ACTUATORS_BY_EQUIPMENT_ID_LOADING',

  GET_ACTUATOR_CIRCUIT_ACTIVITY_SUCCESS = '@controls/GET_ACTUATOR_CIRCUIT_ACTIVITY_SUCCESS',
  GET_ACTUATOR_CIRCUIT_ACTIVITY_ERROR = '@controls/GET_ACTUATOR_CIRCUIT_ACTIVITY_ERROR',
  GET_ACTUATOR_CIRCUIT_ACTIVITY_LOADING = '@controls/GET_ACTUATOR_CIRCUIT_ACTIVITY_LOADING',

  GET_LOCAL_OVERRIDE_ACTIVITY_SUCCESS = '@controls/GET_LOCAL_OVERRIDE_ACTIVITY_SUCCESS',
  GET_LOCAL_OVERRIDE_ACTIVITY_ERROR = '@controls/GET_LOCAL_OVERRIDE_ACTIVITY_ERROR',
  GET_LOCAL_OVERRIDE_ACTIVITY_LOADING = '@controls/GET_LOCAL_OVERRIDE_ACTIVITY_LOADING',

  GET_HVAC_CONTROLS_BY_EQUIPMENT_ID_SUCCESS = '@controls/GET_HVAC_CONTROLS_BY_EQUIPMENT_ID_SUCCESS',
  GET_HVAC_CONTROLS_BY_EQUIPMENT_ID_ERROR = '@controls/GET_HVAC_CONTROLS_BY_EQUIPMENT_ID_ERROR',
  GET_HVAC_CONTROLS_BY_EQUIPMENT_ID_LOADING = '@controls/GET_HVAC_CONTROLS_BY_EQUIPMENT_ID_LOADING',

  GET_HVAC_CONTROLS_BY_SITE_ID_SUCCESS = '@controls/GET_HVAC_CONTROLS_BY_SITE_ID_SUCCESS',
  GET_HVAC_CONTROLS_BY_SITE_ID_ERROR = '@controls/GET_HVAC_CONTROLS_BY_SITE_ID_ERROR',
  GET_HVAC_CONTROLS_BY_SITE_ID_LOADING = '@controls/GET_HVAC_CONTROLS_BY_SITE_ID_LOADING',

  GET_THERMOSTAT_ACTIVITY_BY_ID_SUCCESS = '@controls/GET_THERMOSTAT_ACTIVITY_BY_ID_SUCCESS',
  GET_THERMOSTAT_ACTIVITY_BY_ID_ERROR = '@controls/GET_THERMOSTAT_ACTIVITY_BY_ID_ERROR',
  GET_THERMOSTAT_ACTIVITY_BY_ID_LOADING = '@controls/GET_THERMOSTAT_ACTIVITY_BY_ID_LOADING',

  GET_TEMP_CONTROL_STATUS_TIMESERIES_BY_ID_SUCCESS = '@controls/GET_TEMP_CONTROL_STATUS_TIMESERIES_BY_ID_SUCCESS',
  GET_TEMP_CONTROL_STATUS_TIMESERIES_BY_ID_ERROR = '@controls/GET_TEMP_CONTROL_STATUS_TIMESERIES_BY_ID_ERROR',
  GET_TEMP_CONTROL_STATUS_TIMESERIES_BY_ID_LOADING = '@controls/GET_TEMP_CONTROL_STATUS_TIMESERIES_BY_ID_LOADING',

  GET_SYSTEM_OVERRIDE_ACTIVITY_BY_CONTROL_ID_SUCCESS = '@controls/GET_SYSTEM_OVERRIDE_ACTIVITY_BY_CONTROL_ID_SUCCESS',
  GET_SYSTEM_OVERRIDE_ACTIVITY_BY_CONTROL_ID_ERROR = '@controls/GET_SYSTEM_OVERRIDE_ACTIVITY_BY_CONTROL_ID_ERROR',
  GET_SYSTEM_OVERRIDE_ACTIVITY_BY_CONTROL_ID_LOADING = '@controls/GET_SYSTEM_OVERRIDE_ACTIVITY_BY_CONTROL_ID_LOADING',

  GET_LIGHT_READINGS_BY_CONTROL_BOARD_ID_SUCCESS = '@controls/GET_LIGHT_READINGS_BY_CONTROL_BOARD_ID_SUCCESS',
  GET_LIGHT_READINGS_BY_CONTROL_BOARD_ID_ERROR = '@controls/GET_LIGHT_READINGS_BY_CONTROL_BOARD_ID_ERROR',
  GET_LIGHT_READINGS_BY_CONTROL_BOARD_ID_LOADING = '@controls/GET_LIGHT_READINGS_BY_CONTROL_BOARD_ID_LOADING',

  GET_SCHEDULER_READINGS_BY_SCHEDULER_ID_SUCCESS = '@controls/GET_SCHEDULER_READINGS_BY_SCHEDULER_ID_SUCCESS',
  GET_SCHEDULER_READINGS_BY_SCHEDULER_ID_ERROR = '@controls/GET_SCHEDULER_READINGS_BY_SCHEDULER_ID_ERROR',
  GET_SCHEDULER_READINGS_BY_SCHEDULER_ID_LOADING = '@controls/GET_SCHEDULER_READINGS_BY_SCHEDULER_ID_LOADING',

  GET_THERMOSTATS_BY_SITE_ID_SUCCESS = '@controls/GET_THERMOSTATS_BY_SITE_ID_SUCCESS',
  GET_THERMOSTATS_BY_SITE_ID_ERROR = '@controls/GET_THERMOSTATS_BY_SITE_ID_ERROR',
  GET_THERMOSTATS_BY_SITE_ID_LOADING = '@controls/GET_THERMOSTATS_BY_SITE_ID_LOADING',

  GET_CONTROLLED_HVACS_BY_ORG_ID_SUCCESS = '@controls/GET_CONTROLLED_HVACS_BY_ORG_ID_SUCCESS',
  GET_CONTROLLED_HVACS_BY_ORG_ID_ERROR = '@controls/GET_CONTROLLED_HVACS_BY_ORG_ID_ERROR',
  GET_CONTROLLED_HVACS_BY_ORG_ID_LOADING = '@controls/GET_CONTROLLED_HVACS_BY_ORG_ID_LOADING',
  CREATE_CURRENT_UPDATE_MODE = '@controls/CREATE_CURRENT_UPDATE_MODE',

  GET_HVAC_CONTROLS_BY_SITE_ID_AGGREGATE_SUCCESS = '@controls/GET_HVAC_CONTROLS_BY_SITE_ID_AGGREGATE_SUCCESS',
  GET_HVAC_CONTROLS_BY_SITE_ID_AGGREGATE_ERROR = '@controls/GET_HVAC_CONTROLS_BY_SITE_ID_AGGREGATE_ERROR',
  GET_HVAC_CONTROLS_BY_SITE_ID_AGGREGATE_LOADING = '@controls/GET_HVAC_CONTROLS_BY_SITE_ID_AGGREGATE_LOADING',
}

export const getTemperatureControlsBySiteId = (siteId: string | number) => ({
  type: 'API_GET',
  path: `${apiBase}/temperature/site/${siteId}`,
  success: {
    type: Actions?.GET_TEMPERATURE_CONTROLS_BY_SITE_ID_SUCCESS,
    siteId,
  },
  error: {
    type: Actions.GET_TEMPERATURE_CONTROLS_BY_SITE_ID_ERROR,
    siteId,
  },
  loading: {
    type: Actions.GET_TEMPERATURE_CONTROLS_BY_SITE_ID_LOADING,
    siteId,
  },
});

export const getControlsOverviewBySiteId = (siteId: string | number) => ({
  type: 'API_GET',
  path: `${apiBase}/control-stats/site/${siteId}/overview`,
  success: {
    type: Actions.GET_CONTROLS_OVERVIEW_BY_SITE_ID_SUCCESS,
    siteId,
  },
  error: {
    type: Actions.GET_CONTROLS_OVERVIEW_BY_SITE_ID_ERROR,
    siteId,
  },
  loading: {
    type: Actions.GET_CONTROLS_OVERVIEW_BY_SITE_ID_LOADING,
    siteId,
  },
});

export const getSchedulerByEquipmentId = (equipmentId: string | number) => ({
  type: 'API_GET',
  path: `${apiBase}/schedulers/equipment/${equipmentId}`,
  success: { type: Actions.GET_SCHEDULER_BY_EQUIPMENT_ID_SUCCESS, equipmentId },
  error: { type: Actions.GET_SCHEDULER_BY_EQUIPMENT_ID_ERROR, equipmentId },
  loading: { type: Actions.GET_SCHEDULER_BY_EQUIPMENT_ID_LOADING, equipmentId },
});

export const getSchedulerBySiteId = (siteId: string | number) => ({
  type: 'API_GET',
  path: `${apiBase}/schedulers/site/${siteId}`,
  success: { type: Actions.GET_SCHEDULERS_BY_SITE_ID_SUCCESS, siteId },
  error: { type: Actions.GET_SCHEDULERS_BY_SITE_ID_ERROR, siteId },
  loading: { type: Actions.GET_SCHEDULERS_BY_SITE_ID_LOADING, siteId },
});

export const showUpdateControlWorkingModeModal = () => ({
  type: Actions.TOGGLE_UPDATE_CONTROL_WORKING_MODE_MODAL,
  value: true,
});

export const hideUpdateControlModeModal = () => ({
  type: Actions.TOGGLE_UPDATE_CONTROL_WORKING_MODE_MODAL,
  value: false,
});

export const patchSchedulerWorkingMode = (
  schedulerId: number,
  mode: WorkingMode
) => ({
  type: 'API_PATCH',
  path: `${apiBase}/schedulers/${schedulerId}/working-mode`,
  payload: { workingMode: mode, propagateConfig: true },
  success: {
    type: Actions.PATCH_SCHEDULER_WORKING_MODE_SUCCESS,
    schedulerId,
  },
  error: {
    type: Actions.PATCH_SCHEDULER_WORKING_MODE_ERROR,
    schedulerId,
  },
  loading: {
    type: Actions.PATCH_SCHEDULER_WORKING_MODE_LOADING,
    schedulerId,
  },
});

export const patchTemperatureControlWorkingMode = (
  temperatureControlId: number,
  mode: WorkingMode
) => ({
  type: 'API_PATCH',
  path: `${apiBase}/temperature/${temperatureControlId}/working-mode`,
  payload: { workingMode: mode, propagateConfig: true },
  success: {
    type: Actions.PATCH_TEMPERATURE_CONTROL_WORKING_MODE_SUCCESS,
    temperatureControlId,
  },
  error: {
    type: Actions.PATCH_TEMPERATURE_CONTROL_WORKING_MODE_ERROR,
    temperatureControlId,
  },
  loading: {
    type: Actions.PATCH_TEMPERATURE_CONTROL_WORKING_MODE_LOADING,
    temperatureControlId,
  },
});

export const patchHvacControlWorkingMode = (
  hvacControlId: number | string,
  workingMode: WorkingMode,
  id: string
) => {
  return {
    type: 'API_PATCH',
    path: `${apiBase}/thermostat/controls/${hvacControlId}/working-mode`,
    payload: { workingMode, propagateConfig: true },
    success: {
      type: Actions.PATCH_HVAC_CONTROL_WORKING_MODE_SUCCESS,
      hvacControlId,
      workingMode,
      id,
    },
    error: {
      type: Actions.PATCH_HVAC_CONTROL_WORKING_MODE_ERROR,
      hvacControlId,
    },
    loading: {
      type: Actions.PATCH_HVAC_CONTROL_WORKING_MODE_LOADING,
      hvacControlId,
    },
  };
};

export const createCurrentUpdateMode = (
  currentControlModeUpdate?: PrimedControlModeUpdate,
  currentMode?: WorkingMode
) => {
  const equipmentId = currentControlModeUpdate?.equipment?.id;
  const safeId = String(equipmentId) ?? '';
  const primedControlUpdate = equipmentId
    ? { [`${equipmentId}`]: currentControlModeUpdate }
    : {};

  return {
    type: Actions.CREATE_CURRENT_UPDATE_MODE,
    currentControlModeUpdate,
    currentMode,
    primedControlModeUpdateById: primedControlUpdate,
    value: true,
    id: safeId,
  };
};
export const getControlBoardActuatorsForSiteId = (siteId: string | number) => ({
  type: 'API_GET',
  path: `${apiBase}/control-boards/actuators/site/${siteId}`,
  success: {
    type: Actions.GET_CONTROL_BOARD_ACTUATORS_FOR_SITE_ID_SUCCESS,
    siteId,
  },
  error: {
    type: Actions.GET_CONTROL_BOARD_ACTUATORS_FOR_SITE_ID_ERROR,
    siteId,
  },
  loading: {
    type: Actions.GET_CONTROL_BOARD_ACTUATORS_FOR_SITE_ID_LOADING,
    siteId,
  },
});

export const getActuatorsByEquipmentId = (equipmentId: string | number) => ({
  type: 'API_GET',
  path: `${apiBase}/control-boards/actuators?equipmentId=${equipmentId}`,
  success: { type: Actions.GET_ACTUATORS_BY_EQUIPMENT_ID_SUCCESS, equipmentId },
  error: { type: Actions.GET_ACTUATORS_BY_EQUIPMENT_ID_ERROR, equipmentId },
  loading: { type: Actions.GET_ACTUATORS_BY_EQUIPMENT_ID_LOADING, equipmentId },
});

export const getActuatorCircuitActivityById = (
  actuatorId: string | number,
  fromDate: number,
  toDate: number
) => {
  const formattedFromDate = formatDateForTimeSeriesApi(new Date(fromDate));
  const formattedToDate = formatDateForTimeSeriesApi(new Date(toDate));

  return {
    type: 'API_GET',
    service: Service.timeSeries,
    path: `/actuators/${actuatorId}?from=${formattedFromDate}&to=${formattedToDate}`,
    success: {
      type: Actions.GET_ACTUATOR_CIRCUIT_ACTIVITY_SUCCESS,
      actuatorId,
    },
    error: { type: Actions.GET_ACTUATOR_CIRCUIT_ACTIVITY_ERROR, actuatorId },
    loading: {
      type: Actions.GET_ACTUATOR_CIRCUIT_ACTIVITY_LOADING,
      actuatorId,
    },
  };
};

export const getLocalOverrideActivityByControlBoardId = (
  controlBoardId: string | number,
  fromDate: number,
  toDate: number
) => {
  const formattedFromDate = formatDateForTimeSeriesApi(new Date(fromDate));
  const formattedToDate = formatDateForTimeSeriesApi(new Date(toDate));

  return {
    type: 'API_GET',
    service: Service.timeSeries,
    path: `/gateways/status/cb-overridden-state/${controlBoardId}?from=${formattedFromDate}&to=${formattedToDate}`,
    success: {
      type: Actions.GET_LOCAL_OVERRIDE_ACTIVITY_SUCCESS,
      controlBoardId,
    },
    error: {
      type: Actions.GET_LOCAL_OVERRIDE_ACTIVITY_ERROR,
      controlBoardId,
    },
    loading: {
      type: Actions.GET_LOCAL_OVERRIDE_ACTIVITY_LOADING,
      controlBoardId,
    },
  };
};

export const getHvacControlsByEquipmentId = (equipmentId: string | number) => ({
  type: 'API_GET',
  path: `${apiBase}/thermostat/controls/equipment/${equipmentId}`,
  success: {
    type: Actions.GET_HVAC_CONTROLS_BY_EQUIPMENT_ID_SUCCESS,
    equipmentId,
  },
  error: { type: Actions.GET_HVAC_CONTROLS_BY_EQUIPMENT_ID_ERROR, equipmentId },
  loading: {
    type: Actions.GET_HVAC_CONTROLS_BY_EQUIPMENT_ID_LOADING,
    equipmentId,
  },
});

export const getHvacControlsBySiteId = (siteId: string | number) => ({
  type: 'API_GET',
  path: `${apiBase}/thermostat/controls/site/${siteId}`,
  success: {
    type: Actions.GET_HVAC_CONTROLS_BY_SITE_ID_SUCCESS,
    siteId,
  },
  error: { type: Actions.GET_HVAC_CONTROLS_BY_SITE_ID_ERROR, siteId },
  loading: {
    type: Actions.GET_HVAC_CONTROLS_BY_SITE_ID_LOADING,
    siteId,
  },
});

export const getThermostatActivityById = (
  thermostatId: string | number,
  fromDate: Date,
  toDate: Date,
  featureNotificationId?: string
) => {
  const formattedFromDate = formatDateForTimeSeriesApi(fromDate);
  const formattedToDate = formatDateForTimeSeriesApi(toDate);
  const columns = [
    'MODE',
    'WORKING_MODE',
    'COOL_SET_POINT',
    'HEAT_SET_POINT',
    'TEMPERATURE',
    'FAN_RUNNING',
    'FAN_MODE',
    'ONLINE',
    'TEMP_UNITS',
    'COOLING_STAGE1_RUNNING',
    'HEATING_STAGE2_RUNNING',
    'HEATING_STAGE1_RUNNING',
    'COOLING_STAGE2_RUNNING',
    'LOCAL_ADJUSTMENT_OVERRIDE',
    'LOCAL_ADJUSTMENT_OVERRIDE_ENERGYBOX',
    'TIMETABLE_ACTIVE_STATE',
    'HVAC_SOP_SETPOINT_DEVIATION',
    'MAINTENANCE_STATE',
  ];
  return {
    type: 'API_GET',
    service: Service.timeSeries,
    path: `/thermostat/status/${thermostatId}?from=${formattedFromDate}&to=${formattedToDate}&columns=${columns}`,
    success: {
      type: Actions.GET_THERMOSTAT_ACTIVITY_BY_ID_SUCCESS,
      thermostatId,
      featureNotificationId,
    },
    error: {
      type: Actions.GET_THERMOSTAT_ACTIVITY_BY_ID_ERROR,
      thermostatId,
      featureNotificationId,
    },
    loading: {
      type: Actions.GET_THERMOSTAT_ACTIVITY_BY_ID_LOADING,
      thermostatId,
      featureNotificationId,
    },
  };
};

export const getTempControlTimeSeriesById = (
  controlId: string | number,
  fromDate: number,
  toDate: number
) => {
  const formattedFromDate = formatDateForTimeSeriesApi(new Date(fromDate));
  const formattedToDate = formatDateForTimeSeriesApi(new Date(toDate));

  return {
    type: 'API_GET',
    service: Service.timeSeries,
    path: `/temperature-control/status/${controlId}?from=${formattedFromDate}&to=${formattedToDate}`,
    success: {
      type: Actions.GET_TEMP_CONTROL_STATUS_TIMESERIES_BY_ID_SUCCESS,
      controlId,
    },
    error: {
      type: Actions.GET_TEMP_CONTROL_STATUS_TIMESERIES_BY_ID_ERROR,
      controlId,
    },
    loading: {
      type: Actions.GET_TEMP_CONTROL_STATUS_TIMESERIES_BY_ID_LOADING,
      controlId,
    },
  };
};

export const getSystemOverrideActivityByControlId = (
  controlId: string | number,
  fromDate: number,
  toDate: number,
  featureNotificationId?: string
) => {
  const formattedFromDate = formatDateForTimeSeriesApi(new Date(fromDate));
  const formattedToDate = formatDateForTimeSeriesApi(new Date(toDate));

  return {
    type: 'API_GET',
    service: Service.timeSeries,
    path: `/control-agent-module/status/${controlId}?from=${formattedFromDate}&to=${formattedToDate}`,
    success: {
      type: Actions.GET_SYSTEM_OVERRIDE_ACTIVITY_BY_CONTROL_ID_SUCCESS,
      controlId,
      featureNotificationId,
    },
    error: {
      type: Actions.GET_SYSTEM_OVERRIDE_ACTIVITY_BY_CONTROL_ID_ERROR,
      controlId,
      featureNotificationId,
    },
    loading: {
      type: Actions.GET_SYSTEM_OVERRIDE_ACTIVITY_BY_CONTROL_ID_LOADING,
      controlId,
      featureNotificationId,
    },
  };
};

export const getLightReadingsByControlBoardId = (
  controlBoardId: string | number,
  fromDate: number,
  toDate: number
) => {
  const formattedFromDate = formatDateForTimeSeriesApi(new Date(fromDate));
  const formattedToDate = formatDateForTimeSeriesApi(new Date(toDate));

  return {
    type: 'API_GET',
    service: Service.timeSeries,
    path: `/light/sensor/${controlBoardId}?from=${formattedFromDate}&to=${formattedToDate}`,
    success: {
      type: Actions.GET_LIGHT_READINGS_BY_CONTROL_BOARD_ID_SUCCESS,
      controlBoardId,
    },
    error: {
      type: Actions.GET_LIGHT_READINGS_BY_CONTROL_BOARD_ID_ERROR,
      controlBoardId,
    },
    loading: {
      type: Actions.GET_LIGHT_READINGS_BY_CONTROL_BOARD_ID_LOADING,
      controlBoardId,
    },
  };
};

export const getSchedulerReadingsBySchedulerId = (
  schedulerId: string | number,
  fromDate: number,
  toDate: number
) => {
  const formattedFromDate = formatDateForTimeSeriesApi(new Date(fromDate));
  const formattedToDate = formatDateForTimeSeriesApi(new Date(toDate));

  return {
    type: 'API_GET',
    service: Service.timeSeries,
    path: `/scheduler-control/status/${schedulerId}?from=${formattedFromDate}&to=${formattedToDate}`,
    success: {
      type: Actions.GET_SCHEDULER_READINGS_BY_SCHEDULER_ID_SUCCESS,
      schedulerId,
    },
    error: {
      type: Actions.GET_SCHEDULER_READINGS_BY_SCHEDULER_ID_SUCCESS,
      schedulerId,
    },
    loading: {
      type: Actions.GET_SCHEDULER_READINGS_BY_SCHEDULER_ID_LOADING,
      schedulerId,
    },
  };
};

export const getThermostatsBySiteForTiles = (siteId: string | number) => {
  return {
    type: 'API_GET',
    path: `${apiBase}/venstar/controls/site/${siteId}/tiles`,
    success: {
      type: Actions.GET_THERMOSTATS_BY_SITE_ID_SUCCESS,
      siteId,
    },
    error: {
      type: Actions.GET_THERMOSTATS_BY_SITE_ID_ERROR,
      siteId,
    },
    loading: {
      type: Actions.GET_THERMOSTATS_BY_SITE_ID_LOADING,
      siteId,
    },
  };
};

export const getHvacControlsBySiteIdAggregate = (
  siteId: string | number | undefined
) => ({
  type: 'API_GET',
  path: `${apiBase}/aggregate-views/controllable-hvacs/site/${siteId}`,
  success: {
    type: Actions.GET_HVAC_CONTROLS_BY_SITE_ID_AGGREGATE_SUCCESS,
    siteId,
  },
  error: {
    type: Actions.GET_HVAC_CONTROLS_BY_SITE_ID_AGGREGATE_ERROR,
    siteId,
  },
  loading: {
    type: Actions.GET_HVAC_CONTROLS_BY_SITE_ID_AGGREGATE_LOADING,
    siteId,
  },
});

export const getHvacControlsByOrgId = (orgId: string | number | undefined) => ({
  type: 'API_GET',
  path: `${apiBase}/aggregate-views/controllable-hvacs/organization/${orgId}`,
  success: {
    type: Actions.GET_CONTROLLED_HVACS_BY_ORG_ID_SUCCESS,
    orgId,
  },
  error: {
    type: Actions.GET_CONTROLLED_HVACS_BY_ORG_ID_ERROR,
    orgId,
  },
  loading: {
    type: Actions.GET_CONTROLLED_HVACS_BY_ORG_ID_LOADING,
    orgId,
  },
});
